import React from "react";
import { Route, Routes } from "react-router-dom";
import Contactsync from "./contactsync";
import Defaultapps from "./defaultapps";
import Idplist from "./idplist";
import Oauthsetting from "./oauthsetting";

const Multipleidp = (props) => {
  return (
    <>
      <Routes>
        <Route
          element={
            <props.SidebarLayout
              title="Single Sign-On IDP Configurations"
              subtitle={"App ID " + localStorage.getItem("app_id")}
            />
          }
          style={{ backgroundColor: "#263746" }}
        >
          <Route
            index
            element={<Oauthsetting {...props} />}
            path=":id/idp-configuration/:appname/"
          />
        </Route>
        <Route
          element={
            <props.SidebarLayout
              title="Contact Attribute Mapping"
              subtitle={"App ID " + localStorage.getItem("app_id")}
            />
          }
          style={{ backgroundColor: "#263746" }}
        >
          <Route
            element={<Contactsync {...props} />}
            path=":id/contactsync-configurations/:appname/"
          />
        </Route>
        <Route
          element={
            <props.SidebarLayout
              title="Configured Applications"
              subtitle={"App ID " + localStorage.getItem("app_id")}
            />
          }
          style={{ backgroundColor: "#263746" }}
        >
          <Route element={<Idplist {...props} />} path="" />
        </Route>
        <Route
          element={
            <props.SidebarLayout
              title="Default Applications"
              subtitle=""
            />
          }
          style={{ backgroundColor: "#263746" }}
        >
          <Route element={<Defaultapps {...props} />} 
                 path="default-app" />
        </Route>
      </Routes>
    </>
  );
};

export default Multipleidp;
