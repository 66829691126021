import React from "react";
import { Outlet, Navigate } from "react-router-dom";

const PrivateRoute = () => {
  let token = false;
  if (localStorage.getItem("mossotoken")) {
    token = true;
  }
  return token ? <Outlet /> : <Navigate to="/" />;
};
export default PrivateRoute;
