import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import jwtDecode from "jwt-decode";
import React, { useEffect } from "react";

function createData(name, value) {
  return { name, value };
}

let rows = [];

export default function BasicTable(props) {
  const getAttribute = () => {
    const jwtToken = window.location.hash.substring(12);
    const urlParams = new URLSearchParams(window.location.search);
   
    if (jwtToken) {
      var decoded = jwtDecode(jwtToken);
      var idp_id = decoded["idp_id"]
      delete decoded["idp_id"];
      props.updateTestAtrribute(decoded);
      rows = [];
      Object.keys(decoded).forEach(function (key) {
        rows.push(createData(key, decoded[key]));
      });
      delete decoded["iat"];
      delete decoded["exp"];
      localStorage.setItem(idp_id, JSON.stringify(decoded));
    }
  };
  useEffect(() => {
    getAttribute();
  }, []);

  return (
    rows && (
      <Container maxWidth="sm">
        <Alert severity="success">Test Successfull</Alert>
        <br></br>
        <Box display="flex" justifyContent="center" alignItems="center">
          {/* <img
                  alt="success"
                  width="100px"
                  height="100px"
                  src={`../../assets/right.png`}
                  style={{ cursor: "pointer", borderRadius: "50%" }}
                /> */}
        </Box>

        <p>
          <b>Attribute Received</b>
        </p>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            "& > :not(style)": {
              m: 2,
              width: 128,
              height: 128,
              border: 1,
              borderColor: "green",
            },
          }}
        >
          <Table sx={{ minWidth: 520 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <b>Attribute Name</b>
                </TableCell>
                <TableCell align="left">
                  <b>value</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="left">{row.value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Container>
    )
  );
}
