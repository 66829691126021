import RefreshIcon from "@mui/icons-material/Refresh";
import { Box, Stack } from "@mui/material";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import axios from "axios";
import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";

const Loginaudit = (props) => {
  const [formData, setFormData] = useState([]);
  const [isLoad, setIsLoad] = useState(false);
  const ColumnHead = ({columnName}) =>( 
    <Typography variant="h4" component="h3">
      <b>{columnName}</b>
    </Typography>
    )
  
  const columns = [
    {
      name: "username",
      label: <ColumnHead columnName="Username"/>,
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "page_url",
      label: <ColumnHead columnName="Page"/>,
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "idp_name",
      label: <ColumnHead columnName="Provider"/>,
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "access_at",
      label: <ColumnHead columnName="Access At"/>,
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "ip",
      label: <ColumnHead columnName="IP"/>,
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  const getFormDataValues = async () => {
    setIsLoad(true);
    const result = await axios({
      method: "get",
      url:
        process.env["REACT_APP_GET_LOGIN_AUDITS"] +
        localStorage.getItem("app_id"),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("mossotoken")}`,
      },
    })
      .then(function (response) {
        setIsLoad(false);
        return response.data;
      })
      .catch(function (response) {
        setIsLoad(false);
        return false;
      });
    if (result) {
      if (result) {
        setFormData(result.audits);
      } else {
        setFormData([]);
      }
    }
  };
  useEffect(() => {
    getFormDataValues();
  }, []);

  const Title = () => (
    <Stack direction="row">
      <Typography variant="h3" component="h3">
        {" "}
        <b>Refresh Logs</b>
      </Typography>
      <>
        {isLoad ? (
          <CircularProgress color="inherit" />
        ) : (
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="label"
            onClick={getFormDataValues}
          >
            <RefreshIcon />
          </IconButton>
        )}
      </>
    </Stack>
  );
  const options = {
    filterType: "checkbox",
    jumpToPage: true,
  };

  return (
    formData && (
      <Box m="40px">
        <Alert severity="info">
          <Typography variant="h5" component="h2">
            <b>
            Enable login audits from respective App Configuration{" "}
            </b>
          </Typography>
        </Alert>
        <br />

        <MUIDataTable
          title={<Title />}
          data={formData}
          columns={columns}
          options={options}
          key="id"
        />
      </Box>
    )
  );
};

export default Loginaudit;
