import { Box, Button } from "@mui/material";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import React, { useState ,useEffect} from "react";
import DataTable from "react-data-table-component";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import HelpIcon from "@mui/icons-material/Help";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";
import Stack from "@mui/material/Stack";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { v4 as uuid } from "uuid";
import Defaultapps from "../defaultapps";

const Idplist = (props) => {
 
  const navigate = useNavigate();
  const [alert, setAlert] = useState(false);
  const [errorAlert, seterrorAlert] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger'
    },
    buttonsStyling: false
  })

  const handleClickOpen = async (idp_id,app_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let res = await props.deleteIDPConfiguration(idp_id,app_id);
        if(res){
        Swal.fire(
          "Deleted!",
          "Your configurations has been deleted.",
          "success"
        );}
        else{
          swalWithBootstrapButtons.fire(
            'Cancelled',
            'Something went wrong',
            'error'
          )
        }
      }
    });
  };
  const NoDataConst = () => <div>okokok</div>;

  const customStyles = {
    rows: {
      style: {
        minHeight: "75px", // override the row height
      },
    },
    headCells: {
      style: {
        // override the cell padding for head cells
        fontSize: "1.3rem",
        fontWeight: "bold",
      },
    },
    cells: {
      style: {
        // override the cell padding for data cells
        fontSize: "1rem",
      },
    },
  };

  const columns = [
    {
      name: (
        <Typography variant="h4" component="h3">
          <b>IDP Name </b>&nbsp;&nbsp;
        </Typography>
      ),
      cell: (row, index) => (
        <Typography variant="h4" component="h3">
          {row.idp_name}
        </Typography>
      ),
    },
    {
      name: (
        <Typography variant="h4" component="h3">
          {" "}
          <b>SSO Configuration </b>&nbsp;&nbsp;
        </Typography>
      ),
      cell: (row, index) => (
        <Stack spacing={1} direction="row">
          <IconButton
            color="primary"
            aria-label="upload picture"
            component={Link}
            to={"/sso/app-configurations/" + row.idp_id + "/idp-configuration/"+ JSON.parse(row.oauthapp).appname}
          >
            <EditIcon />
          </IconButton>
        </Stack>
      ),
    },
    {
      name: (
        <>
          <Stack direction="row">
          <Typography variant="h4" component="h3">
            <b>Contact Attribute Mapping </b>&nbsp;&nbsp;
          </Typography>

          <HelpIcon
            aria-owns={open ? "mouse-over-popover" : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            style={{marginTop:"0.2rem"}}
          />
          </Stack>

          <Popover
            id="mouse-over-popover"
            sx={{
              pointerEvents: "none",
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "center",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "center",
              horizontal: "left",
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <Typography sx={{ p: 2, width: "15rem" }}>
              Configure the contact sync and login audit settings
            </Typography>
          </Popover>
        </>
      ),
      cell: (row, index) => (
        <IconButton
          color="primary"
          aria-label="upload picture"
          component={Link}
          to={
            "/sso/app-configurations/" +
            row.idp_id +
            "/contactsync-configurations/" + JSON.parse(row.oauthapp).appname
          }
        >
          <EditIcon />
        </IconButton>
      ),
    },
    {
      name: (
        <Typography variant="h4" component="h3">
          <b>Delete </b>&nbsp;&nbsp;
        </Typography>
      ),
      cell: (row, index) => (
        <IconButton
          color="primary"
          aria-label="delete idp"
          onClick={() => handleClickOpen(row.idp_id,row.app_id)}
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  const AlertLayout = () => (
    <>
      {alert ? (
        <Alert severity="success"> Pages Updated Successfully !!</Alert>
      ) : (
        <></>
      )}
      {errorAlert ? (
        <Alert severity="error">Something went wrong!!</Alert>
      ) : (
        <></>
      )}
    </>
  );

  return (
    <Box m="40px">
      {props.fetchdata && props.fetchdata.length != 0 ? (
        <Typography variant="h3" component="h3" marginBottom="20px">
          <Button
            type="submit"
            style={{
              color: "white",
              backgroundColor: "#ff7a59",
              borderRadius: "2px",
              boxShadow: "none",
            }}
            variant="contained"
            onClick={() => navigate("/sso/app-configurations/default-app")}
          >
            Add Application
            <AddIcon />
          </Button>
        </Typography>
      ) : (
        <></>
      )}

      <AlertLayout />

      {props.fetchdata ? (
        props.fetchdata.length != 0 ? (
          <DataTable
            columns={columns}
            data={props.fetchdata}
            fixedHeader
            customStyles={customStyles}
            NoDataComponent={NoDataConst}
            striped
          />
        ):(
          <Defaultapps {...props}/>
        )
       
      ) : (
        <Box display="flex" justifyContent="center" marginTop="40px">
          <CircularProgress></CircularProgress>
        </Box>
      )}
    </Box>
  );
};
export default Idplist;
