import { Box, styled } from "@mui/material";
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import React,{useEffect} from 'react';
import {Link, useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { DefaultAppJson , DefaultAppNameList } from "../../../../defaultappjson";
import Skeleton from '@mui/material/Skeleton';
import Alert from "@mui/material/Alert";

const Defaultapps = (props) => {
  const navigate = useNavigate();
  
  
    const Item = styled(Paper)(({ theme }) => ({
        padding: theme.spacing(1),
        textAlign: 'center',
        height:"20px"
      }));
      
    const handleClick = (event,name) => {
        var unique_id = uuid();
        var small_id = unique_id.slice(0, 8);
        var newIDPUrl =
          "/sso/app-configurations/" + small_id + "/idp-configuration/"+name;
        navigate(newIDPUrl);
    }  
    const Appbox = (props) => {
    
    return (
        <>
    <Card className="card" onClick={(event) => handleClick(event,props.name)}>
      <CardMedia
        sx={{ height: 60 , width: 60,display:"grid",justifySelf:"center"}}
        image={`${process.env["REACT_APP_FRONTEND_URL"]}/assets/images/${DefaultAppJson[props.name]['image']}`} 
        title={DefaultAppJson[props.name]['label']}
      />
                
        <Typography variant="h5" textAlign="center" >
          {DefaultAppJson[props.name]['label']}
        </Typography>
    </Card>
      </>
      )

    }

  return (
    
    <Box m="40px" >
          <Alert severity="info">
            <Typography variant="h5" component="h2">
           If your app is not here you can create with custom OAUTH 2.0 or OIDC (Open ID Connect protocol) app as per your provider's implementation.
          Also, to add your custom app in our list &nbsp; <Link to="/sso/contact"><a><b>Contact Us</b></a></Link>
           </Typography>
          </Alert>
      <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={0} rowSpacing={1} >
      {DefaultAppNameList.map((name, index) => ( 
                    <Grid item >
                       <Appbox name={name}/>
                     </Grid>
                   ))}
      </Grid>
    </Box>
    </Box>
    
  )
}

export default Defaultapps;