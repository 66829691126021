import React from "react";
import { Navigate } from "react-router-dom";
var login_url = process.env["REACT_APP_HUBSPOT_LOGIN_URL"];

function HomePage() {
  if (localStorage.getItem("mossotoken")) {
    return <Navigate to="/sso/app-configurations" />;
  }

  return (window.location.href = login_url);
}
export default HomePage;
