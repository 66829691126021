import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { Box, Button, TextField, Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Stack from "@mui/material/Stack";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useNavigate, useParams, Link ,useSearchParams } from "react-router-dom";
import { DefaultAppJson } from "../../../../defaultappjson";

const Oauthsetting = (props) => {
  let { id ,appname} = useParams();
  const navigate = useNavigate();
  props.updateIDPID(id);
  var oauth_configurations = false;
  var is_idp_already_exists = false
  if (props.fetchdata) {
    for (let i = 0; i < props.fetchdata.length; i++) {
      if (props.fetchdata[i].idp_id == id) {
        is_idp_already_exists = true
        oauth_configurations = props.fetchdata[i].oauthapp;
        props.updateIDPData(oauth_configurations);
      }
    }
  }
  if(!is_idp_already_exists){
    props.updateIDPData(false);
   }

  const [initialValues, setInitialValues] = useState(JSON.parse(oauth_configurations));
 
  const [copied, setCopied] = useState(false);
 
  const onclickCopied = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };
  const handleChangeHeader = () => {
    setCheckHeader(!checkHeader);
  };
  const handleChangeBody = () => {
    setCheckBody(!checkBody);
  };

  const emptyInitialValues = {
    app_id: localStorage.getItem("app_id"),
    idp_id: id,
    idp_name: DefaultAppJson[appname] ? DefaultAppJson[appname]['label'] : "",
    login_btn_text : "",
    appname:appname,
    client_id: "",
    client_secret: "",
    scopes:DefaultAppJson[appname] ?  DefaultAppJson[appname]['scope'] :"",
    auth_endpoint:DefaultAppJson[appname] ? DefaultAppJson[appname]['authorize'] :"",
    token_endpoint: DefaultAppJson[appname] ? DefaultAppJson[appname]['token'] : "",
    send_header: DefaultAppJson[appname] ? ( DefaultAppJson[appname]['send_header'] === '1' ? "true" : "false" ): "false",
    send_body: DefaultAppJson[appname] ? (DefaultAppJson[appname]['send_body'] === '1' ? "true" : "false") :"false",
    userinfo_endpoint: DefaultAppJson[appname] ?( DefaultAppJson[appname]['type'] =='oauth' ? DefaultAppJson[appname]['userinfo'] : "none") : "",
    sync_enabled: "false",
    loginaudit_enabled: "false",
    flow_type:DefaultAppJson[appname] ?DefaultAppJson[appname]['type'] : "oauth",
    sync_attr: {
      email: "false",
      firstname: "false",
      lastname: "false",
      company: "false",
    },
    custom_attr : [] 
  };
  const [checkHeader, setCheckHeader] = useState(emptyInitialValues.send_header==="true" ? true: false );
  const [checkBody, setCheckBody] = useState(emptyInitialValues.send_body==="true" ? true : false);

  useEffect(() => {
    if(!(appname in DefaultAppJson)){
      navigate('/sso/app-configurations')
   }
    if (!initialValues) {
      setInitialValues(emptyInitialValues);
    }
    if (initialValues) {
      if (initialValues.send_header === "true") {
        setCheckHeader(true);
      } else {
        setCheckHeader(false);
      }
      if (initialValues.send_body === "true") {
        setCheckBody(true);
      } else {
        setCheckBody(false);
      }
    }
  }, []);


  const Progress = () =>
    props.progressbar ? <LinearProgress color="secondary" /> : <></>;

  const AlertLayout = () => (
    <>
      {props.oauthsettingalert ? (
        <Alert severity="success">
          <AlertTitle>
            Configuration saved successfully ! 
          </AlertTitle>
        </Alert>
      ) : (
        <></>
      )}
      {props.erroralert ? (
        <Alert severity="error">Something went wrong!!</Alert>
      ) : (
        <></>
      )}
    </>
  );

  return (
    initialValues && (
      <>
        <Container maxWidth="sl">
          <AlertLayout />
        </Container>

        <Grid container spacing={3}>
          <Grid item xs={6} md={7}>
            <Box m="10px">
              <Progress />
              <Formik
                onSubmit={props.handleFormSubmit}
                initialValues={initialValues}
                validationSchema={props.checkoutSchema}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                }) => (
                  <form onSubmit={props.handleFormSubmit}>
                    <Box
                      display="flex"
                      gap="10px"
                      gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                      sx={{
                        marginTop: 2,
                        paddingLeft: "2rem",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "end",
                        width: "90%",
                        justifyContent: "end",
                      }}
                    >
                      <Button
                        style={{ boxShadow: "none" }}
                        variant="outlined"
                        onClick={(event) => {
                          window.open("https://youtu.be/PHPTZiB-h3Y");
                        }}
                      >
                        <YouTubeIcon style={{ color: "#FF0000" }} /> &nbsp;{" "}
                        <b>Video Guide</b>
                      </Button>
                      <Button
                        style={{ boxShadow: "none" }}
                        variant="outlined"
                        onClick={(event) => {
                          window.open(
                            DefaultAppJson[appname]['guide']
                            
                          );
                        }}
                      >
                        <MenuBookIcon style={{ color: "#1976d2" }} />
                        &nbsp; <b>Setup Guide</b>
                      </Button>
                    </Box>

                    <Box
                      display="grid"
                      gap="10px"
                      gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                      sx={{
                        marginTop: 2,
                        paddingLeft: "2rem",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "start",
                        width: "90%",
                      }}
                    >
                      <TextField
                        value={DefaultAppJson[appname]['type']}
                        name="flow_type"
                        sx={{ display: "none" }}
                      />
                      <TextField
                        value={values.appname}
                        name="appname"
                        sx={{ display: "none" }}
                      />

                      <TextField
                        required
                        fullWidth
                        variant="outlined"
                        type="text"
                        label="IDP Name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.idp_name}
                        name="idp_name"
                        error={!!touched.idp_name && !!errors.idp_name}
                        helperText={touched.idp_name && errors.idp_name}
                        sx={{ mt: 2 }}
                        inputProps={{ maxLength: 50 }}
                      />
                       <TextField
                        required
                        fullWidth
                        variant="outlined"
                        type="text"
                        label="Login Button Text"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.login_btn_text}
                        name="login_btn_text"
                        error={!!touched.login_btn_text && !!errors.login_btn_text}
                        helperText={touched.login_btn_text && errors.login_btn_text}
                        sx={{ mt: 2 }}
                        inputProps={{ maxLength: 50 }}
                      />
                      <TextField
                        id="input-with-icon-adornment"
                        fullWidth
                        InputProps={{
                          readOnly: true,
                          endAdornment: copied ? (
                            <Chip
                              label="Copied"
                              style={{
                                color: "white",
                                backgroundColor: "#ff7a59",
                              }}
                            />
                          ) : (
                            <CopyToClipboard
                              text={process.env["REACT_APP_CALLBACKURL"]}
                            >
                              <IconButton
                                aria-label="delete"
                                onClick={onclickCopied}
                              >
                                <ContentCopyIcon />
                              </IconButton>
                            </CopyToClipboard>
                          ),
                        }}
                        label="Callback URL"
                        type="text"
                        value={process.env["REACT_APP_CALLBACKURL"]}
                        name="appname"
                        sx={{ mt: 2 }}
                      />
                      <TextField
                        required
                        fullWidth
                        variant="outlined"
                        type="text"
                        label="Client Id"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.client_id}
                        name="client_id"
                        error={!!touched.client_id && !!errors.client_id}
                        helperText={touched.client_id && errors.client_id}
                        sx={{ mt: 2 }}
                      />
                      <TextField
                        required
                        fullWidth
                        variant="outlined"
                        type="password"
                        label="Client Secret"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.client_secret}
                        name="client_secret"
                        error={
                          !!touched.client_secret && !!errors.client_secret
                        }
                        helperText={
                          touched.client_secret && errors.client_secret
                        }
                        sx={{ mt: 2 }}
                      />
                      <TextField
                        fullWidth
                        variant="outlined"
                        type="text"
                        label="Scopes"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.scopes}
                        name="scopes"
                        error={!!touched.scopes && !!errors.scopes}
                        helperText={touched.scopes && errors.scopes}
                        sx={{ mt: 2 }}
                      />
                      <Stack direction="row" spacing={0}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="sendinheader"
                              checked={checkHeader}
                              onClick={handleChangeHeader}
                              style={{ color: "#ff7a59" }}
                            />
                          }
                          label="Send in header"
                          sx={{ mt: 2 }}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={checkBody}
                              onClick={handleChangeBody}
                              name="sendinbody"
                              style={{ color: "#ff7a59" }}
                            />
                          }
                          label="Send in body"
                          sx={{ mt: 2, ml: 4 }}
                        />
                      </Stack>
                      <TextField
                        required
                        fullWidth
                        variant="outlined"
                        type="url"
                        label="Authorization Endpoint"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.auth_endpoint}
                        name="auth_endpoint"
                        error={
                          !!touched.auth_endpoint && !!errors.auth_endpoint
                        }
                        helperText={
                          touched.auth_endpoint && errors.auth_endpoint
                        }
                        sx={{ mt: 2 }}
                      />
                      <TextField
                        required
                        fullWidth
                        variant="outlined"
                        type="url"
                        label="Token Endpoint"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.token_endpoint}
                        name="token_endpoint"
                        error={
                          !!touched.token_endpoint && !!errors.token_endpoint
                        }
                        helperText={
                          touched.token_endpoint && errors.token_endpoint
                        }
                        sx={{ mt: 2 }}
                      />
                      <TextField
                        required = {values.flow_type == "openidconnect" ? false : true}
                        fullWidth
                        variant="outlined"
                        type={values.flow_type == "openidconnect" ? "text" : "url"}
                        label="Userinfo Endpoint"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.userinfo_endpoint}
                        name="userinfo_endpoint"
                        error={
                          !!touched.userinfo_endpoint &&
                          !!errors.userinfo_endpoint
                        }
                        helperText={
                          touched.userinfo_endpoint && errors.userinfo_endpoint
                        }
                        sx={{ mt: 2,display: values.flow_type == "openidconnect" ? "none" : "" }}
                      />
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="start"
                      mt="20px"
                      paddingLeft="2rem"
                    >
                      <Button
                        type="submit"
                        style={{
                          color: "white",
                          backgroundColor: "#ff7a59",
                          borderRadius: "2px",
                          boxShadow: "none",
                        }}
                        variant="contained"
                      >
                        Save & Test Configuration
                      </Button>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </Box>
                  </form>
                )}
              </Formik>
            </Box>
          </Grid>
          {props.isInstruction ? (
            <Grid item xs={4} md={5} mt="5rem">
              <List
                sx={{
                  width: "100%",
                  maxWidth: 460,
                  bgcolor: "background.paper",
                  boxShadow: "3",
                  borderRadius: "2px",
                }}
                subheader={
                  <ListSubheader
                    style={
                      {
                        // background: "linear-gradient(to right bottom, #00BDA5, #00A4BD)",
                      }
                    }
                  >
                    <Typography
                      sx={{ display: "inline" }}
                      component="span"
                      variant="h4"
                      color="text.primary"
                      size="xs"
                      fontWeight={600}
                      fontFamily={[
                        "Avenir Next W02",
                        "Lexend Deca",
                        "Helvetica",
                        "Arial",
                        "sans-serif",
                      ].join(",")}
                      gutterBottom
                      // fontFamily={["Avenir Next W02","Lexend Deca","Helvetica","Arial","sans-serif"].join(',')}
                    >
                      What Next ?
                    </Typography>
                  </ListSubheader>
                }
              >
                <ListItem alignItems="flex-start">
                  <Stack>
                    <ListItemText
                      // primary="1. Protect your content"
                      secondary={
                        <React.Fragment>
                          <Typography
                            sx={{ display: "inline" }}
                            component="span"
                            variant="h4"
                            color="text.primary"
                            size="xs"
                            fontWeight={600}
                            fontFamily={[
                              "Avenir Next W02",
                              "Lexend Deca",
                              "Helvetica",
                              "Arial",
                              "sans-serif",
                            ].join(",")}
                            gutterBottom
                          >
                            Protect your content
                          </Typography>
                          <br />
                          <br />
                          <Typography variant="subtitle2">
                            To protect your HubSpot website pages, knowledge
                            base, and landing pages, click the button and select
                            the pages you want to protect
                          </Typography>
                        </React.Fragment>
                      }
                    />
                    <ListItemText>
                      <Link
                        to="/sso/pagerestriction"
                        style={{ 
                          color:"#0091ae",
                          fontWeight:300,
                          fontFamily:[
                            "Avenir Next W02",
                            "Lexend Deca",
                            "Helvetica",
                            "Arial",
                            "sans-serif",
                          ].join(",")}}
                      >
                        Page Restriction
                      </Link>
                    </ListItemText>
                  </Stack>
                </ListItem>
                <Divider component="li" />
                <ListItem alignItems="flex-start">
                  <Stack>
                    <ListItemText
                      secondary={
                        <React.Fragment>
                          <Typography
                            sx={{ display: "inline" }}
                            component="span"
                            variant="h4"
                            color="text.primary"
                            size="xs"
                            fontWeight={600}
                            gutterBottom
                            fontFamily={[
                              "Avenir Next W02",
                              "Lexend Deca",
                              "Helvetica",
                              "Arial",
                              "sans-serif",
                            ].join(",")}
                          >
                            Sync SSO Users
                          </Typography>
                          <br />
                          <br />
                          <Typography variant="subtitle2">
                            Sync your IdP users with HubSpot Contacts
                          </Typography>
                        </React.Fragment>
                      }
                    />
                    <ListItemText>
                      <Link
                        to={
                          "/sso/app-configurations/" +
                          id +
                          "/contactsync-configurations/" + appname 
                        }
                        style={{ 
                          color:"#0091ae",
                          fontWeight:300,
                          fontFamily:[
                            "Avenir Next W02",
                            "Lexend Deca",
                            "Helvetica",
                            "Arial",
                            "sans-serif",
                          ].join(",")}}
                      >
                        Sync SSO Users
                      </Link>
                    </ListItemText>
                  </Stack>
                </ListItem>
                <Divider component="li" />
                <ListItem alignItems="flex-start">
                  <Stack>
                    <ListItemText
                      // primary="3. Track visitors"
                      secondary={
                        <React.Fragment>
                          <Typography
                            sx={{ display: "inline" }}
                            component="span"
                            variant="h4"
                            color="text.primary"
                            size="xs"
                            fontWeight={600}
                            gutterBottom
                            fontFamily={[
                              "Avenir Next W02",
                              "Lexend Deca",
                              "Helvetica",
                              "Arial",
                              "sans-serif",
                            ].join(",")}
                          >
                            Track visitors
                          </Typography>
                          <br />
                          <br />
                          <Typography variant="subtitle2">
                            Track your visitors performing SSO on your HubSpot
                            website pages, knowledge base and landing pages
                          </Typography>
                        </React.Fragment>
                      }
                    />
                    <ListItemText>
                      <Link
                        to="/sso/loginaudit"
                        style={{ 
                          color:"#0091ae",
                          fontWeight:300,
                          fontFamily:[
                            "Avenir Next W02",
                            "Lexend Deca",
                            "Helvetica",
                            "Arial",
                            "sans-serif",
                          ].join(",")}}
                      >
                        Track Visitor
                      </Link>
                    </ListItemText>
                  </Stack>
                </ListItem>
              </List>
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      </>
    )
  );
};

export default Oauthsetting;
