import InfoIcon from "@mui/icons-material/Info";
import { Box, Button, TextField } from "@mui/material";
import Alert from "@mui/material/Alert";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import LinearProgress from "@mui/material/LinearProgress";
import MenuItem from "@mui/material/MenuItem";
import Popover from "@mui/material/Popover";
import Select from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { Stack } from "@mui/system";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useParams,Link,useNavigate } from "react-router-dom";
import Autocomplete from '@mui/material/Autocomplete';
import AddIcon from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';
import DialogTitle from '@mui/material/DialogTitle';
import RemoveIcon from '@mui/icons-material/Remove';
import axios from "axios";
import CircularProgress from '@mui/material/CircularProgress';
import { DefaultAppJson } from "../../../../defaultappjson";

const Contactsync = (props) => {
  let { id ,appname} = useParams();
  const navigate = useNavigate();
  var idpdata = false;
  var testAttribute = false;
  if (props.fetchdata) {
    for (let i = 0; i < props.fetchdata.length; i++) {
      if (props.fetchdata[i].idp_id == id) {
        idpdata = props.fetchdata[i].oauthapp;
        testAttribute = props.fetchdata[i].test_configurations;
        props.updateIDPData(idpdata);
      }
    }
  }
  if (localStorage.getItem(id)) {
    testAttribute = localStorage.getItem(id);
  }

  props.updateIDPID(id);

  const [anchorEl, setAnchorEl] = useState(null);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
 const [hubContactList,setHubContactList] =useState(props.hubContactList)
 const [tempIdpAttrributes,setTempIdpAttrributes] = useState([])

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const [idpAttributes, setIdpAttribute] = useState(
    Object.keys(JSON.parse(testAttribute))
  );
  const [email, setemail] = useState("");
  const [firstname, setfirstname] = useState("");
  const [lastname, setlastname] = useState("");
  const [company, setcompany] = useState("");
  const [attributeList,setAttributeList] = useState(JSON.parse(idpdata).custom_attr ? (JSON.parse(idpdata).custom_attr!='false'? JSON.parse(idpdata).custom_attr:[]) :[])
  
  const addNewRow = () => {
    let newAttributeList =[...attributeList]
    if(newAttributeList.length == 0 || (newAttributeList[newAttributeList.length-1][0]['label']!=null && newAttributeList[newAttributeList.length-1][1]!="")){
     newAttributeList.push([{"name":null,"label":null},""])
     setAttributeList(newAttributeList)
    }
  };
  const hubFourAttribute = ['Email',"First Name", "Last Name","Company Name"]
 
  const handleChangeemail = (event) => {
    setemail(event.target.value);
  };
  const handleChangefirst = (event) => {
    setfirstname(event.target.value);
  };
  const handleChangelast = (event) => {
    setlastname(event.target.value);
  };
  const handleChangecomp = (event) => {
    setcompany(event.target.value);
  };
  const [initialValues, setInitialValues] = useState(JSON.parse(idpdata));
  const [syncEnable, setSyncenable] = useState(false);
  const [loginAuditEnable, setLoginAuditEnable] = useState(false);
  const [hubSelectedLabel,setHubSelectedLabel] = useState([])
  const [syncattr, setsyncattr] = useState({
    email: "false",
    firstname: "false",
    lastname: "false",
    company: "false",
  });

  const emptyInitialValues = {
    app_id: localStorage.getItem("app_id"),
    idp_id: id,
    idp_name: "",
    login_btn_text:"",
    appname:"",
    client_id: "",
    client_secret: "",
    scopes: "",
    auth_endpoint: "",
    token_endpoint: "",
    send_header: "true",
    send_body: "false",
    userinfo_endpoint: "",
    flow_type:"",
    sync_enabled: "false",
    loginaudit_enabled: "false",
    sync_attr: syncattr,
    custom_attr : [] 
  };

  const handleChangesync = () => {
    setSyncenable(!syncEnable);
  };
  const handleChangeloginaudit = () => {
    setLoginAuditEnable(!loginAuditEnable);
  };

  const Progress = () =>
    props.progressbar ? <LinearProgress color="secondary" /> : <></>;

  const AlertLayout = () => (
    <>
      {props.mapalert ? (
        <Alert severity="success">Attribute sync successfully</Alert>
      ) : (
        <></>
      )}
      {props.erroralert ? (
        <Alert severity="error">Something went wrong!!</Alert>
      ) : (
        <></>
      )}
      {props.mapErrorAlert ? (
        <Alert severity="error">
          No attribute maped ! Please map at least one IDP attribute below.
        </Alert>
      ) : (
        <></>
      )}
    </>
  );
 
  useEffect(() => {
    if(!(appname in DefaultAppJson)){
      navigate('/sso/app-configurations')
    }
    addNewRow()
    if (!initialValues) {
      setInitialValues(emptyInitialValues);
    }
    if (initialValues) {
      if (initialValues.sync_enabled === "true") {
        setSyncenable(true);
      } else {
        setSyncenable(false);
      }
      if (initialValues.loginaudit_enabled === "true") {
        setLoginAuditEnable(true);
      } else {
        setLoginAuditEnable(false);
      }

      setemail(initialValues.sync_attr.email);
      setfirstname(initialValues.sync_attr.firstname);
      setlastname(initialValues.sync_attr.lastname);
      setcompany(initialValues.sync_attr.company);
      let hublabel = []
      for(let i=0;i<attributeList.length;i++){
         hublabel.push(attributeList[i][0]['label'])
      }
      setHubSelectedLabel(hublabel)

      let syncattrfromdb = initialValues.sync_attr
      let tempidpattributes = []
      let keys = Object.keys(syncattrfromdb)    
      for(let i=0;i<keys.length;i++){
        if(syncattrfromdb[keys[i]]!="false" && !tempidpattributes.includes(syncattrfromdb[keys[i]])){
           tempidpattributes.push(syncattrfromdb[keys[i]])
        }
      }
     
      setTempIdpAttrributes(tempidpattributes)
    }
  }, []);

  const MappingRow = ({idpAttributes,index}) => {
    const handleChange = (event,val,i) => {
      let newAttributeList = [...attributeList]
      newAttributeList[index][i] = val!=null ? val : {"name":null,"label":null}
      setAttributeList(newAttributeList)
       
      if(i==0){
      let hublabel = [...hubSelectedLabel]
      hublabel[index] = val ? val['label'] : null
      setHubSelectedLabel(hublabel)}
    };
    const handleDelete = () =>{
      let temp = [...attributeList]
      temp.splice(index,1)
      if(temp.length==0){
        temp.push([{"name":null,"label":null},''])
      }

      setAttributeList(temp)
      
      
      let hublabel = [...hubSelectedLabel]
      hublabel.splice(index,1)
      setHubSelectedLabel(hublabel)
    
    }
    
    return hubContactList && (<>
    
    <TableRow
                      key="Email"
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                     <TableCell sx={{marginLeft:1, minWidth: 200}} >
                     
                        <Autocomplete
                              isOptionEqualToValue={(option, value) => { 
                               return option.label === value}}
                               options={hubContactList}
                               getOptionDisabled={(option) =>{
                               if(hubSelectedLabel.includes(option.label) || hubFourAttribute.includes(option.label) ){
                                return true
                               } 
                               return false
                               }                                
                              }
                              id="combo-box-demo"
                              value={attributeList[index][0]['label']}
                              onChange={(event, newValue) => handleChange(event,newValue,0)}
                              disablePortal
                              sx={{ width: 300 }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  required={attributeList[index][1]!="" ? true : (index<attributeList.length-1 ? true : false)}
                                  placeholder="Select HubSpot Contact Attribute"
                                />
                              )}
                            />
                      
                      </TableCell>
                      <TableCell align="left" >
                        <FormControl sx={{marginLeft:1, minWidth: 350 }}>
                          <Select
                            required={attributeList[index][0]['label']!=null ? true : (index<attributeList.length-1 ? true : false)}
                            displayEmpty
                            value={attributeList[index][1]}
                            onChange={(event) => handleChange(event,event.target.value,1)}
                            inputProps={{ "aria-label": "Without label" }}
                            name={attributeList[index][0]}
                          >
                            <MenuItem disabled value="false">
                              <em>Select the below options</em>
                            </MenuItem>
                            <MenuItem value="">None</MenuItem>

                            {idpAttributes.map((name) => (
                              <MenuItem
                                key={name}
                                value={name}
                              >
                                {name}
                              </MenuItem>
                            ))}
                          </Select>
                         
                        </FormControl>
                      </TableCell>
                      <TableCell>
                      { index < attributeList.length  ?
                      <Button variant="contained" size="small" onClick = {handleDelete}  style={{minWidth:"fit-content",backgroundColor:"#ff7a59",borderRadius:"3px",boxShadow:"none",height:"2rem"}}><RemoveIcon /></Button>
                      : <></>
                        } 
                      </TableCell>
                      <TableCell>
                      { index == attributeList.length -1 ?
                      <Button variant="contained" size="small" onClick = {addNewRow}  style={{minWidth:"fit-content",backgroundColor:"#ff7a59",borderRadius:"3px",boxShadow:"none",height:"2rem"}}><AddIcon /></Button>
                        : <></>
                        } 
                      </TableCell>
                    </TableRow>
    
    </>);
  }

  return (
    
    initialValues && hubContactList && (
      <Box
        m="10px"
        style={
          props.pagenotrestricted
            ? {}
            : { pointerEvents: "none", opacity: "0.4" }
        }
      >
        <Box m="20px">
        <Stack direction="row">
        <Link
                        to={"/sso/app-configurations/" + id + "/idp-configuration/"+appname}
                        style={{ 
                          textDecorationLine: 'none',
                          fontSize:"1rem",
                          // color:"#0091ae",
                          fontWeight:300,
                          marginLeft:"0.5rem",
                          fontFamily:[
                            "Avenir Next W02",
                            "Lexend Deca",
                            "Helvetica",
                            "Arial",
                            "sans-serif",
                          ].join(",")}}
           >
                        IDP Configurations / 
          </Link>
          <Typography
           style={{ 
            fontSize:"1rem",
            fontWeight:300,
            marginLeft:"0.5rem",
            fontFamily:[
              "Avenir Next W02",
              "Lexend Deca",
              "Helvetica",
              "Arial",
              "sans-serif",
            ].join(",")}}>
            Contact sync
          </Typography>
          </Stack>
          <br />
          <Progress />
          <Alert severity="info">
            <Typography variant="h6" component="h2">
              Map your hubspot contact attribute with third party application /
              IDP attribute recived in test configuration result.{" "}
              <b>Please map at least one IDP attribute below.</b>
            </Typography>
          </Alert>
          <AlertLayout />
         
          
        </Box>
        <Formik
          onSubmit={props.handleFormSubmit}
          initialValues={initialValues.sync_attr}
          validationSchema={props.checkoutSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={props.handleFormSubmit}>
              <Box
                display="grid"
                gap="0px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  
                  paddingLeft: "2rem",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  width: "60%",
                }}
              >
                <TextField
                  value="map"
                  name="mapping"
                  sx={{ display: "none" }}
                />

                <TextField
                  value={JSON.stringify({"custom_attr":attributeList})}
                  name="custom_attr"
                  sx={{ display: "none" }}
                />
                
                <Table sx={{ minWidth: 520 }} aria-label="simple table">
                  <TableHead>
                    
                    <TableRow>
                      <TableCell>
                        <b>
                          <h2>HubSpot Contact Attribute</h2>
                        </b>
                      </TableCell>
                      <TableCell align="left">
                        <b>
                          <h2>Attribute Names From IDP</h2>
                        </b>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  
                   
                    <TableRow
                      key="Email"
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <Typography variant="h5" component="h2">
                          Email
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <FormControl sx={{ m: 1, minWidth: 350 }}>
                          <Select
                            displayEmpty
                            value={email}
                            onChange={handleChangeemail}
                            inputProps={{ "aria-label": "Without label" }}
                            name="email"
                          >
                            {/* {Object.keys(idpAttributes).map((type) => (
                      <TypeSelectMenuItem value={idpAttributes[type]} key={type}>
                        {idpAttributes[type]}
                      </TypeSelectMenuItem>
                    ))} */}
                            <MenuItem disabled value="false">
                              <em>Select the below options</em>
                            </MenuItem>
                            <MenuItem value="">None</MenuItem>
                          {idpAttributes.length!=0 ? idpAttributes.map((name) => (
                              <MenuItem
                                key={name}
                                value={name}
                              >
                                {name}
                              </MenuItem>
                            )) : 
                            tempIdpAttrributes.map((name) => (
                              <MenuItem
                                key={name}
                                value={name}
                              >
                                {name}
                              </MenuItem>
                            ))
                            
                            }
                          </Select>
                          <FormHelperText style={{ color: "red" }}>
                            *Select the attribute that contains email address
                          </FormHelperText>
                        </FormControl>
                      </TableCell>
                    </TableRow>

                    {/* <TableRow
                      key="Email"
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <Typography variant="h5" component="h2">
                          Email
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <TextField
                          fullWidth
                          variant="outlined"
                          type="text"
                          placeholder="Email"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.email === "false" ? "" : values.email}
                          name="email"
                          error={!!touched.email && !!errors.email}
                          helperText={touched.email && errors.email}
                          sx={{ mt: 0 }}
                        />
                      </TableCell>
                    </TableRow> */}

                    <TableRow
                      key="Firstname"
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <Typography variant="h5" component="h2">
                          First Name
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <FormControl sx={{ m: 1, minWidth: 350 }}>
                          <Select
                            value={firstname}
                            onChange={handleChangefirst}
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                            name="firstname"
                          >
                            <MenuItem disabled value="false">
                              <em>Select the below options</em>
                            </MenuItem>
                            <MenuItem value="">None</MenuItem>
                          {idpAttributes.length!=0 ? idpAttributes.map((name) => (
                              <MenuItem
                                key={name}
                                value={name}
                              >
                                {name}
                              </MenuItem>
                            )) : 
                            tempIdpAttrributes.map((name) => (
                              <MenuItem
                                key={name}
                                value={name}
                              >
                                {name}
                              </MenuItem>
                            ))
                            
                            }
                          </Select>
                        </FormControl>
                        {/* <TextField
                          fullWidth
                          variant="outlined"
                          type="text"
                          placeholder="Firstname"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={
                            values.firstname === "false" ? "" : values.firstname
                          }
                          name="firstname"
                          error={!!touched.firstname && !!errors.firstname}
                          helperText={touched.firstname && errors.firstname}
                          sx={{ mt: 0 }}
                        /> */}
                      </TableCell>
                    </TableRow>

                    <TableRow
                      key="Lastname"
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <Typography variant="h5" component="h2">
                          Last Name
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <FormControl sx={{ m: 1, minWidth: 350 }}>
                          <Select
                            value={lastname}
                            onChange={handleChangelast}
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                            name="lastname"
                          >
                            <MenuItem disabled value="false">
                              <em>Select the below options</em>
                            </MenuItem>
                            <MenuItem value="">None</MenuItem>
                            {idpAttributes.length!=0 ? idpAttributes.map((name) => (
                              <MenuItem
                                key={name}
                                value={name}
                              >
                                {name}
                              </MenuItem>
                            )) : 
                            tempIdpAttrributes.map((name) => (
                              <MenuItem
                                key={name}
                                value={name}
                              >
                                {name}
                              </MenuItem>
                            ))
                            }
                          </Select>
                        </FormControl>
                        {/* <TextField
                          fullWidth
                          variant="outlined"
                          type="text"
                          placeholder="Lastname"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={
                            values.lastname === "false" ? "" : values.lastname
                          }
                          name="lastname"
                          error={!!touched.lastname && !!errors.lastname}
                          helperText={touched.lastname && errors.lastname}
                          sx={{ mt: 0 }}
                        /> */}
                      </TableCell>
                    </TableRow>

                    <TableRow
                      key="Company"
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <Typography variant="h5" component="h2">
                          Company Name
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <FormControl sx={{ m: 1, minWidth: 350 }}>
                          <Select
                            value={company}
                            onChange={handleChangecomp}
                            // displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                            name="company"
                            displayEmpty
                            // renderValue={company !== "false" ? undefined : () => "Select the below options"}
                          >
                            <MenuItem disabled value="false">
                              <em>Select the below options</em>
                            </MenuItem>
                            <MenuItem value="">None</MenuItem>
                            {idpAttributes.length!=0 ? idpAttributes.map((name) => (
                              <MenuItem
                                key={name}
                                value={name}
                              >
                                {name}
                              </MenuItem>
                            )) : 
                            tempIdpAttrributes.map((name) => (
                              <MenuItem
                                key={name}
                                value={name}
                              >
                                {name}
                              </MenuItem>
                            ))
                            }
                          </Select>
                        </FormControl>
                        {/* <TextField
                          fullWidth
                          variant="outlined"
                          type="text"
                          placeholder="company"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={
                            values.company === "false" ? "" : values.company
                          }
                          name="company"
                          error={!!touched.company && !!errors.company}
                          helperText={touched.company && errors.company}
                          sx={{ mt:
                             0 }}
                        /> */}
                      </TableCell>
                    </TableRow>
                    <TableCell>
                    <b>
                      <h2>Custom Attribute Mapping</h2>
                    </b>
                    </TableCell>
                    {idpAttributes.length!=0 ?
                    attributeList.map((attribute, index) => ( 
                       <MappingRow idpAttributes={idpAttributes} index={index} />
                   )):
                   attributeList.map((attribute, index) => ( 
                    <MappingRow idpAttributes={tempIdpAttrributes} index={index} />
                ))
                   }
                  </TableBody>
                </Table>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="sync_enabled"
                      checked={syncEnable}
                      onClick={handleChangesync}
                      style={{ color: "#ff7a59" }}
                    />
                  }
                  label="Enable Contact sync"
                  sx={{ mt: 2, paddingLeft: 2 }}
                />
                <Stack direction="row">
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="loginaudit_enabled"
                        checked={loginAuditEnable}
                        onClick={handleChangeloginaudit}
                        style={{ color: "#ff7a59" }}
                      />
                    }
                    label="Enable login audits for this application"
                    sx={{ mt: 2, paddingLeft: 2 }}
                  />

                  <>
                    <InfoIcon
                      aria-owns={open ? "mouse-over-popover" : undefined}
                      aria-haspopup="true"
                      onMouseEnter={handlePopoverOpen}
                      onMouseLeave={handlePopoverClose}
                      style={{ marginTop: "1.6rem" }}
                    />

                    <Popover
                      id="mouse-over-popover"
                      sx={{
                        pointerEvents: "none",
                      }}
                      open={open}
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: "center",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "center",
                        horizontal: "left",
                      }}
                      onClose={handlePopoverClose}
                      disableRestoreFocus
                    >
                      <Typography sx={{ p: 2, width: "18rem" }}>
                        Enabling this feature will audit users authenticated by
                        this provider
                      </Typography>
                    </Popover>
                  </>
                </Stack>
              </Box>
              <Box
                display="flex"
                justifyContent="start"
                mt="20px"
                paddingLeft="3rem"
              >
                <Button
                  type="submit"
                  style={{
                    color: "white",
                    backgroundColor: "#ff7a59",
                    borderRadius: "2px",
                    boxShadow: "none",
                  }}
                  variant="contained"
                >
                  Save
                </Button>
              </Box>
            </form>
          )}
        </Formik>
        <br />
        <br />
        <br />
      </Box>
    )
  );
};
export default Contactsync;
