import LaunchIcon from "@mui/icons-material/Launch";
import Looks3RoundedIcon from "@mui/icons-material/Looks3Rounded";
import LooksOneRoundedIcon from "@mui/icons-material/LooksOneRounded";
import LooksTwoRoundedIcon from "@mui/icons-material/LooksTwoRounded";
import RefreshIcon from "@mui/icons-material/Refresh";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Box, Button, fabClasses } from "@mui/material";
import Alert from "@mui/material/Alert";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import TextField from "@mui/material/TextField";
import SearchIcon from '@mui/icons-material/Search';
import Typography from "@mui/material/Typography";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { CopyBlock, googlecode } from "react-code-blocks";
import DataTable from "react-data-table-component";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import Popover from '@mui/material/Popover';
import HelpIcon from "@mui/icons-material/Help";
import LoadingButton from '@mui/lab/LoadingButton';
import { update } from "lodash";
const Pagerestriction = (props) => {
  const [alert, setAlert] = useState(false);
  const [errorAlert, seterrorAlert] = useState(false);
  const [progressbar, setProgressbar] = useState(false);
  const [websitePageData,setWebsitePageData] = useState(props.websitePageData)
  const [landingPageData,setLandingPageData] = useState(props.landingPageData)
  const [blogPageData, setBlogPageData] = useState(props.blogPageData)
  const [landingParentCheck, setLandingParentCheck] = useState(false);
  const [websiteParentCheck, setWebsiteParentCheck] = useState(false);
  const [blogParentCheck, setBlogParentCheck] = useState(false);
  const [value, setValue] = useState("1");
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const handleChangewebsiteparent = () => {
    var isAllWebCheck = websiteParentCheck ? false : true;
    setWebsiteParentCheck(isAllWebCheck);
    let newFormdata = [...websitePageData];
    for (let i = 0; i < websitePageData.length; i++) {
      newFormdata[i].page_restricted = String(isAllWebCheck);
    }
    setWebsitePageData(newFormdata)
  };
  const handleChangelandingparent = () => {
    var isAllLandingCheck = landingParentCheck ? false : true;
    setLandingParentCheck(isAllLandingCheck);
    let newFormdata = [...landingPageData];
    for (let i = 0; i < landingPageData.length; i++) {
      newFormdata[i].page_restricted = String(isAllLandingCheck);
    }
    setLandingPageData(newFormdata);
  };

  const handleChangeBlogparent = () => {
    var isAllBlogCheck = blogParentCheck ? false : true;
    setBlogParentCheck(isAllBlogCheck);
    let newFormdata = [...blogPageData];
    for (let i = 0; i < blogPageData.length; i++) {
      newFormdata[i].page_restricted = String(isAllBlogCheck);
    }
    setBlogPageData(newFormdata);
  };
  
  const handleSubmit = (event, pagename) => {
    event.preventDefault();
    props.isTokenValidate(localStorage.getItem("mossotoken"));
    let data = pagename === "website_page" ? websitePageData : (pagename === "landing_page"  ? landingPageData : blogPageData)
    setProgressbar(true);
    axios({
      method: "post",
      url:
        process.env["REACT_APP_UPDATE_PAGES_DATA"] +
        localStorage.getItem("app_id") +
        "/" +
        pagename,
      data: { formdata: data },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("mossotoken")}`,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then(function (response) {
        setProgressbar(false);
        setAlert(true);
        // props.getFormDataValues(pagename,false);
        setTimeout(() => {
          setAlert(false);
        }, 3000);
        
      })
      .catch(function (response) {
        setProgressbar(false);
        seterrorAlert(true);
        setTimeout(() => {
          seterrorAlert(false);
        }, 3000);
      });
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const Progress = () =>
    progressbar ? <LinearProgress color="secondary" /> : <></>;

  let codetext = `<script> var app_id =${localStorage.getItem(
    "app_id"
  )};</script>
<script src="https://code.jquery.com/jquery-3.3.1.min.js"></script>
<script src="https://cdn.jsdelivr.net/npm/popper.js@1.12.9/dist/umd/popper.min.js"
 integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossorigin="anonymous"></script>
<script src="https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/js/bootstrap.min.js" 
integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>
<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css"
 integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
<script src="${process.env["REACT_APP_BACKEND_DOMAIN"]}/miniorange-hubspot.js"></script>
`;
  const Instructionlist = (props) => (
    <>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          "& > :not(style)": {
            m: 1,
            width: "100%",
            height: "100%",
          },
        }}
        paddingTop={0}
      >
        {props.page_type!=='blog' && props.page_type!=='knowledge_base'  ?
        <Alert severity="info" style={{ marginTop: "0px" }}>
          <Typography variant="h4" component="h2">
            <b>
              {" "}
              To enable page restriction manually follow the below steps
            </b>
          </Typography>
        </Alert> : <></> }
        <Grid container spacing={0}>
          <Grid xs={12}>
            <List
              xs={6}
              sx={{
                width: "100%",
                maxWidth: "100%",
                bgcolor: "background.paper",
                boxShadow: "1",
                borderRadius: "1px",
              }}
            >
              <ListItem alignItems="flex-start">
                <ListItemIcon>
                  <LooksOneRoundedIcon />
                </ListItemIcon>
                <Stack>
                  <ListItemText
                    secondary={
                      <React.Fragment>
                        <Typography
                          variant="h5"
                          color="black"
                          fontFamily={[
                            "Avenir Next W02",
                            "Lexend Deca",
                            "Helvetica",
                            "Arial",
                            "sans-serif",
                          ].join(",")}
                        >
                          {props.page_type==='knowledge_base' ? <span>In your HubSpot account, navigate to <b>Service tab</b> {'>'} <b>Knowledge Base</b></span>:   (props.page_type==='blog' ? <span>In your HubSpot account, navigate to <b>Marketing tab </b> {'>'} <b>website </b>{'>'} <b>blog</b></span> : <span>In your HubSpot account, navigate to <b>Marketing tab</b> {'>'} <b>website </b> {'>'} <b>website page</b></span>) }
                        </Typography>
                      </React.Fragment>
                    }
                  />
                  <ListItemText></ListItemText>
                </Stack>
              </ListItem>

              <ListItem alignItems="flex-start">
                <ListItemIcon>
                  <LooksTwoRoundedIcon />
                </ListItemIcon>
                <Stack>
                  <ListItemText
                    secondary={
                      <React.Fragment>
                        <Typography
                          variant="h5"
                          color="black"
                          fontFamily={[
                            "Avenir Next W02",
                            "Lexend Deca",
                            "Helvetica",
                            "Arial",
                            "sans-serif",
                          ].join(",")}
                        >
                          Go to the {props.page_type!=='blog' ?<b>page</b> : <b>blog</b>} on which you want to enable SSO and click
                          on <b>Edit button</b> then go to {props.page_type!=='blog' ?'page' : 'blog'} <b>Setting {'>'} Advanced setting</b>
                        </Typography>
                      </React.Fragment>
                    }
                  />
                  <ListItemText></ListItemText>
                </Stack>
              </ListItem>

              <ListItem alignItems="flex-start">
                <ListItemIcon>
                  <Looks3RoundedIcon />
                </ListItemIcon>
                <Stack>
                  <ListItemText
                    secondary={
                      <React.Fragment>
                        <Typography
                          variant="h5"
                          color="black"
                          fontFamily={[
                            "Avenir Next W02",
                            "Lexend Deca",
                            "Helvetica",
                            "Arial",
                            "sans-serif",
                          ].join(",")}
                        >
                          Copy the below script in HTML field and save it
                        </Typography>
                      </React.Fragment>
                    }
                  />
                  <ListItemText></ListItemText>
                </Stack>
              </ListItem>

              <Grid
                xs={12}
                marginLeft="4rem"
                sx={{
                  height: "8rem",
                  // overflow: "hidden",
                  overflowY: "scroll",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <CopyBlock
                  text={codetext}
                  language="javascript"
                  showLineNumbers={false}
                  theme={googlecode}
                  codeBlock
                />
              </Grid>
            </List>
          </Grid>
        </Grid>
      </Box>
    </>
  );

  const customStyles = {
    rows: {
      style: {
        minHeight: "75px", // override the row height
      },
    },
    headCells: {
      style: {
        // override the cell padding for head cells
        fontSize: "1.3rem",
        fontWeight: "bold",
      },
    },
    cells: {
      style: {
        // override the cell padding for data cells
        fontSize: "1rem",
      },
    },
  };

  function swap(json){
    var ret = {};
    for(var key in json){
      ret[json[key]] = key;
    }
    return ret;
  }

  

  const RoleBasedAccessDropdown = ({row,pagename,check}) => {
    const [roleName, setRoleName] = useState(() => row.checked_contact_list.map((id) => props.contactList[id]));
    var contact_list_len = Object.keys(props.contactList).length;
      const isAllSelected = contact_list_len > 0 && roleName.length === contact_list_len;

      const handleChangeRole = (event,index) => {
      
        const value = event.target.value;
        var curr_checked_list = value 
        var swaped_contactList = swap(props.contactList)
        if (value[value.length - 1] === "all") {
          curr_checked_list = roleName.length === contact_list_len ? [] : Object.keys(swaped_contactList)
        }
        setRoleName(curr_checked_list);
       
        var checked_list_id =[]
        for(let i=0;i<curr_checked_list.length;i++){
          checked_list_id.push(swaped_contactList[curr_checked_list[i]])
        }
  
       if(pagename === "website_page"){
              let newFormdata = [...websitePageData];
              newFormdata[index].checked_contact_list = checked_list_id
        }
        else if(pagename === "landing_page"){
          let newFormdata = [...landingPageData];
          newFormdata[index].checked_contact_list = checked_list_id
        }
        else{
              let newFormdata = [...blogPageData];
              newFormdata[index].checked_contact_list = checked_list_id
        }
        
      };
   return (
    <div>
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="demo-multiple-checkbox-label">Contact List</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id={row.page_id}
          key={row.page_id}
          multiple
          value={roleName}
          onChange={(e) => handleChangeRole(e,row.index)}
          input={<OutlinedInput label="Contact List" />}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
          disabled = {!check}
          multiselect-select-all = {true}
        >
        <MenuItem
          value="all"
        >
          <ListItemIcon>
            <Checkbox
              checked={isAllSelected}
              indeterminate={
                roleName.length > 0 && roleName.length < contact_list_len
              }
            />
          </ListItemIcon>
          <ListItemText        
            primary="Select All"
          />
        </MenuItem>
        { Object.keys(props.contactList).map((contact_list_id) => {
              return (
              <MenuItem key={contact_list_id} value={props.contactList[contact_list_id]}>
              <Checkbox checked={roleName.indexOf(props.contactList[contact_list_id]) > -1} />
              <ListItemText primary={props.contactList[contact_list_id]} />
              </MenuItem>
              )
          })
        }
         
        </Select>
        {!check ? <FormHelperText>To enable it, first restrict the page by checking the checkbox </FormHelperText>:<></>}
        
      </FormControl>
    </div> 
  );

  }
   const handleChangewebsite = (index) => {
      let newFormdata = [...websitePageData]; 
      if (websiteParentCheck && newFormdata[index].page_restricted == "true") {
        setWebsiteParentCheck(false);
      }
      newFormdata[index].page_restricted =  newFormdata[index].page_restricted === "false" ? "true" : "false"
      
    };
   const handleChangelanding = (index) => {
      let newFormdata = [...landingPageData]; 
      if (landingParentCheck && newFormdata[index].page_restricted == "true") {
        setLandingParentCheck(false);
      }
      newFormdata[index].page_restricted =  newFormdata[index].page_restricted === "false" ? "true" : "false"
    };

    const handleChangeblog = (index) => {
      let newFormdata = [...blogPageData]; 
      if (blogParentCheck && newFormdata[index].page_restricted == "true") {
        setBlogParentCheck(false);
      }
      newFormdata[index].page_restricted =  newFormdata[index].page_restricted === "false" ? "true" : "false"
    };
  
    const handleChangetab = (event, newValue) => {
      setValue(newValue);
    };
  const RowCheckBox =  ({row,check,handleCheck}) => {
   
    
    return (
      <FormControlLabel
      control={
        <Checkbox
          name={row.page_id}
          checked={check}
          onChange={(e)=>handleCheck(e,row.index)}
          style={{ color: "#ff7a59" }}
          key={row.page_id}
        />
      }
      name={row.page_id}
      key={row.page_id}
      />
    )
  }


  const AlertLayout = () => (
    <>
      {alert ? (
        <Alert severity="success"> Pages Updated Successfully !!</Alert>
      ) : (
        <></>
      )}
      {errorAlert ? (
        <Alert severity="error">Something went wrong!!</Alert>
      ) : (
        <></>
      )}
    </>
  );

  const Title = ({ pagename ,color}) => (
    <Typography variant="h3" component="h3">
      <LoadingButton
        loading = {props.isLoading}
        loadingPosition="start"
        style={{
          color: "white",
          backgroundColor: "#ff7a59",
          borderRadius: "2px",
          boxShadow: "none",
        }}
        variant="contained"
        startIcon={<RefreshIcon />}
        onClick={(e) => props.getFormDataValues(pagename,true)}
      >
        Refresh
      </LoadingButton>
    </Typography>
  );
  
  const Pagetable = ({ pagename ,pagedata, handleChange, handleChangeParent, parentCheck}) => {

    const [check,setCheck] = useState(pagedata ? pagedata.map((row) => row.page_restricted == "true" ? true : false) : null)
      
    const handleCheck = (e, index) => {
      const checkList = [...check]
      checkList[index] = e.target.checked
      setCheck(checkList)
      handleChange(index)
    };
  
    const Columns = (pagename) => {
      const [anchorEl, setAnchorEl] = useState(null);
      const open = Boolean(anchorEl);
  
      const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
      };
      const handlePopoverClose = () => {
        setAnchorEl(null);
      };
     
  
      return [
        {
          name: (
            <Typography variant="h4" component="h3">
              <b>Page Name And URL </b>&nbsp;&nbsp;
            </Typography>
          ),
          cell: (row) => (
            <Stack spacing={1}>
              <Typography variant="h5" component="h3" color="#0091ae">
                {row.name}
              </Typography>
              <Link
                href={row.url}
                target="_blank"
                underline="none"
                color="#6e6f70"
              >
                <Stack spacing={1} direction="row">
                  <LaunchIcon />
                  <p>{row.url}</p>
                </Stack>
              </Link>
            </Stack>
          ),
        },
        {
          name: (
            <Typography variant="h4" component="h3">
              {" "}
              <b>SSO Enable/Disable </b>&nbsp;&nbsp;
              <FormControlLabel
                control={
                  <Checkbox
                    checked={parentCheck}
                    onChange={handleChangeParent}
                    style={{ color: "#ff7a59" }}
                  />
                }
              />
            </Typography>
          ),
          cell: (row) => (
            <>
             <RowCheckBox row={row} check={check[row.index]} handleCheck = {handleCheck} />
            </>
          ),
        },
        {
          name: (
            <Stack direction="row" >
            <Typography variant="h4" component="h3">
              {" "}
              <b>Role Based Access</b>&nbsp;
            </Typography>
            <Popover
              id="mouse-over-popover"
              sx={{
                pointerEvents: "none",
              }}
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "center",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "center",
                horizontal: "left",
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              <Typography sx={{ p: 2, width: "15rem" }}>
              A page is accessible only to contacts which are in your selected contact list. If you don't select any list then by default anyone can access after successful SSO.
              </Typography>
            </Popover>
             <HelpIcon
              aria-owns={open ? "mouse-over-popover" : undefined}
              aria-haspopup="true"
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
              style={{marginTop:"0.2rem"}}
            />
            </Stack>
          ),
          cell: (row) => (
            <>
              <RoleBasedAccessDropdown row={row} pagename={pagename} check ={check[row.index]}/>
            </>
          ),
        },
  
      ];
    };
  
    
    const [searchValue, setSearchValue] = useState('');
    const filteredData = pagedata ? pagedata.filter(page => page.name.toLowerCase().includes(searchValue.toLowerCase())) : pagedata;
    return pagedata ? (
          pagedata.length != 0 ? (
            <form onSubmit={(e) => handleSubmit(e, pagename)}>
              <Grid item md={12} xs={12} sm={12} >
                <TextField
                  sx={{
                      mb: 3,
                      mt: 2,
                      backgroundColor: "white",
                      boxShadow: "2",
                      borderRadius: "4%",
                        }}
                  fullWidth
                  value={searchValue}
                  onChange={e => setSearchValue(e.target.value)}
                  placeholder="Search Pages....."
                  InputProps={{
                       endAdornment: (
                          <IconButton>
                           <SearchIcon />
                          </IconButton>
                            ),
                        }}
                    />
              </Grid>
              
              <DataTable
                columns={Columns(pagename)}
                data={filteredData}
                title={<Title pagename={pagename} color="#ff7a59"/>}
                fixedHeader
                customStyles={customStyles}
                striped
                pagination
                keyField="page_id"
              />
              <Box display="flex" justifyContent="start" mt="20px">
                <Button
                  type="submit"
                  style={{
                    color: "white",
                    backgroundColor: "#ff7a59",
                    borderRadius: "2px",
                    boxShadow: "none",
                  }}
                  variant="contained"
                >
                  Save
                </Button>
              </Box>
            </form>
          ) : (
            <Card
              sx={{ minWidth: 275 }}
              style={{ backgroundColor: "rgb(229, 246, 253)" }}
            >
              <CardContent>
              <Title pagename={pagename} color="#014361" />
                
                <div
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  <Typography
                    variant="h3"
                    color="rgb(1, 67, 97)"
                    justifyContent="center"
                  >
                    <strong> No pages found in your HubSpot account</strong>
                  </Typography>
                </div>
                <br />
                <div
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  <Typography variant="h5" color="rgb(1, 67, 97)">
                    Whenever you create page in your hubspot account you will
                    show all page list here and you can protect your content
                    behind SSO by enabling checkbox
                    <br />
                    <br />
                  </Typography>
                </div>
                <div
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                  }}
                ></div>
              </CardContent>
              <CardActions></CardActions>
            </Card>
          )
        ) : (
          <Box display="flex" justifyContent="center" marginTop="40px">
            <CircularProgress></CircularProgress>
          </Box>
        )
  };

  const Tabhead = ({ title }) => {
    return (
      <Typography variant="h5" component="h3" color="#ff7a59">
        {" "}
        <b>{title}</b>
      </Typography>
    );
  };

  return (
    <Box
      m="40px"
      style={
        props.pagenotrestricted ? {} : { pointerEvents: "none", opacity: "0.4" }
      }
      paddingTop={0}
      marginTop={0}
    >
      <Progress />
      <AlertLayout />

      <Grid>
        {/* <Grid item xs={6} md={5} mt={1.7}>
          <item>
            <Instructionlist page_type ="" />
          </item>
        </Grid> */}

        <br />
        <Alert severity="info">
          <Typography variant="h4" component="h2">
            <b>
              {" "}
              Check the check box to enable page restrictions on the page.{" "}
            </b>
          </Typography>
        </Alert>
        <TabContext value={value} indicatorColor="red">
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleChangetab}
              aria-label="lab API tabs example"
            >
              <Tab
                label={
                  value == "1" ? (
                    <Tabhead title="Website Pages" />
                  ) : (
                    "Website Pages"
                  )
                }
                value="1"
              />
              <Tab
                label={
                  value == "2" ? (
                    <Tabhead title="Landing Pages" />
                  ) : (
                    "Landing Pages"
                  )
                }
                value="2"
              />
              <Tab
                label={
                  value == "3" ? (
                    <Tabhead title="Blog" />
                  ) : (
                    "Blog"
                  )
                }
                value="3"
              />
              <Tab
                label={
                  value == "4" ? (
                    <Tabhead title="knowledge base" />
                  ) : (
                    "knowledge base"
                  )
                }
                value="4"
              />
            </TabList>
          </Box>
          <TabPanel value="1">
            <Pagetable 
              pagename="website_page"
              pagedata = {websitePageData}
              handleChange={handleChangewebsite}
              handleChangeParent={handleChangewebsiteparent}
              parentCheck={websiteParentCheck}
            />
          </TabPanel>
          <TabPanel value="2">
            <Pagetable 
              pagename="landing_page"
              pagedata = {landingPageData}
              handleChange={handleChangelanding}
              handleChangeParent={handleChangelandingparent}
              parentCheck={landingParentCheck}
              />
          </TabPanel>
          <TabPanel value="3">
            <Pagetable 
             pagename="blog_page" 
             pagedata = {blogPageData} 
             handleChange={handleChangeblog}
             handleChangeParent={handleChangeBlogparent}
             parentCheck={blogParentCheck}
             />
          </TabPanel>
          <TabPanel value="4">
          <Instructionlist page_type="knowledge_base" />
          </TabPanel>
        </TabContext>
      </Grid>
    </Box>
  );
};

export default Pagerestriction;
