import { CssBaseline, ThemeProvider } from "@mui/material";
import axios from "axios";
import jwtDecode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { Outlet, Route, Routes, useNavigate ,Navigate} from "react-router-dom";
import Configuration from "./scenes/configuration";
import Sidebar from "./scenes/global/Sidebar";
import Topbar from "./scenes/global/Topbar";
import HomePage from "./scenes/home";
import PrivateRoute from "./scenes/routes/privateroutes";
import { ColorModeContext, useMode } from "./theme";

function App() {
  const history = useNavigate();
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const SidebarLayout = ({ title, subtitle, cust_css }) => (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <Sidebar isSidebar={isSidebar} />
          <main className="content" style={cust_css}>
            <Topbar
              setIsSidebar={setIsSidebar}
              title_top={title}
              subtitle_top={subtitle}
            />
            <Outlet />
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );

  const isTokenValidate = async (token) => {
    const result = await axios({
      method: "get",
      url: process.env["REACT_APP_TOKEN_VALIDATE_URL"],
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(function (response) {
        return true;
      })
      .catch(function (response) {
        return false;
      });
    return result;
  };

  const setTokenToLocalStorage = async () => {
    const fragment = window.location.hash;
    let isTestSso= window.location.toString().includes("testSSO");
    if(localStorage.getItem("mossotoken") && !fragment){
      var isValidate =  await isTokenValidate(localStorage.getItem("mossotoken"));
      if(!isValidate){
        localStorage.clear();
        history("/")
      }
    }
    else if (fragment && fragment.includes("mossotoken") ) {
      if(!isTestSso){
      const appInstallToken = fragment.substring(12);
      const isValidate = await isTokenValidate(appInstallToken);
      if (isValidate) {
        localStorage.setItem("mossotoken", appInstallToken);
        var decoded = jwtDecode(appInstallToken);
        localStorage.setItem("username", decoded.username);
        localStorage.setItem("app_id", decoded.app_id);
        window.open(
          process.env.REACT_APP_FRONTEND_URL,
          "_self"
        );
      }
       else {
        localStorage.clear()
        history("/");
      }
     }
    } else {
      localStorage.clear()
      history("/");
    }
  };
 
  useEffect(() => {
      setTokenToLocalStorage();
  }, []);

  return (
    <Routes>
      <Route element={<PrivateRoute />}>
        <Route element={<Configuration />} path="sso/*" />
      </Route>
      <Route element={<HomePage />} path="/" />
    </Routes>
  );
}

export default App;
