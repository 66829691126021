import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container,ToggleButton,Switch, ToggleButtonGroup, Alert,LinearProgress, Typography,Chip,IconButton, Grid, Box, TextField, Button, FormControlLabel, Checkbox, Stack, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { CopyToClipboard } from "react-copy-to-clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
const SAMLBroker = (props) => {
  const [copiedIdpEntityId, setCopiedIdpEntityId] = useState(false);
  const [copiedIdpSsoUrl, setCopiedIdpSsoUrl] = useState(false);
  const [copiedCertificate, setCopiedCertificate] = useState(false);
  const [samlProgressbar, setSamlProgressbar] = useState(props.samlProgressbar);
  const [samlAlert, setSamlAlert] = useState(props.samlAlert);
  const [samlErrorAlert, setSamlErrorAlert] = useState(props.samlErrorAlert);
  const appId = localStorage.getItem("app_id");
  const idpEntityId = process.env['REACT_APP_BACKEND_URL']+appId;
  const idpSSOUrl = process.env['REACT_APP_SAML_IDP_SSO_URL']+appId;
  const [idpList, setIdpList] = useState(props.fetchdata);
  const [formData, setFormData] = useState(props.samlFormData);

  const AlertLayout = () => (
    <>
      {samlAlert ? (
        <Alert severity="success">Configuration saved successfully !</Alert>
      ) : (
        <></>
      )}
      {samlErrorAlert ? (
        <Alert severity="error">Something went wrong!!</Alert>
      ) : (
        <></>
      )}
    </>
  );

  const Progress = () =>
    samlProgressbar ? <LinearProgress color="secondary" /> : <></>;

 
  const onclickCopiedIdpEntityId = () => {
    setCopiedIdpEntityId(true);
    setTimeout(() => {
      setCopiedIdpEntityId(false);
    }, 1000);
  };
  const onclickCopiedIdpSsoUrl = () => {
    setCopiedIdpSsoUrl(true);
    setTimeout(() => {
      setCopiedIdpSsoUrl(false);
    }, 1000);
  };
  const onclickCopiedCertificate = () => {
    setCopiedCertificate(true);
    setTimeout(() => {
      setCopiedCertificate(false);
    }, 1000);
  };


  const handleIdpListChange = (event) => {
    const selectedIdps = event.target.value;
    setFormData(prevState => ({ ...prevState, idp_list: selectedIdps }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevState => ({ ...prevState, [name]: value }));
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFormData(prevState => ({ ...prevState, [name]: checked }));
  };

  return idpList && (
    <>
      {/* <Container maxWidth="sl">
          <AlertLayout />
       </Container> */}
       <Box ml="2rem" mr="2rem">
       <AlertLayout />
      <Alert severity="info">
        <Typography variant="h5" component="h1">
           If you have an enterprise plan with HubSpot, you can utilize the default single sign-on (SSO) feature of HubSpot along with multiple IDP applications.
        </Typography>
      </Alert>
      <Progress />
       </Box>
     
      <Grid container spacing={1}>
        
        <Grid item xs={12} md={6}>
          <Box m="10px"> 
        
            <form onSubmit={(e)=>props.handleSamlFormSubmit(e, formData)}>
              <Box 
                display="grid"
                gap="10px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  marginTop: 2,
                  paddingLeft: "2rem",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  width: "90%",
                }}>
                  <Typography variant="h3" component="h3" fontWeight={700} >
                    SP Metadata
                  </Typography>
                <TextField
                  required
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Audience URI (Service Provider Entity ID)"
                  onChange={handleInputChange}
                  value={formData.sp_entity_id}
                  name="sp_entity_id" // Add the name attribute
                  id="sp_entity_id" // Add the id attribute
                  sx={{ mt: 2 }}
                  inputProps={{ maxLength: 5000 }}
                />

                <TextField
                  required
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Sign on URL, ACS, Recipient, or Redirect"
                  onChange={handleInputChange}
                  value={formData.acs_url}
                  name="acs_url" // Add the name attribute
                  id="acs_url" // Add the id attribute
                  sx={{ mt: 2 }}
                  inputProps={{ maxLength: 5000 }}
                />

                <FormControl sx={{ mt: 2 }} fullWidth required>
                  <InputLabel id="idp-list-label">IDP List</InputLabel>
                  <Select
                    required
                    labelId="idp-list-label"
                    multiple
                    label = "apps"
                    value={formData.idp_list}
                    onChange={(event) => {                          
                      handleIdpListChange(event);
                    }}
                    MenuProps={{
                      
                      PaperProps: {
                        style: {
                          maxHeight: 224,
                          width: 250,
                        },
                      },
                    }}
                  >
                    {idpList.map((option) => (
                      <MenuItem key={option.idp_id} value={option.idp_id}>
                        {option.idp_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControlLabel
                control={
                    <Switch
                    checked={formData.is_enabled} // Use formData.is_enabled as the checked value
                    onChange={handleCheckboxChange} // Pass the handleCheckboxChange function
                    name="is_enabled" // Set the name attribute
                    color="primary" // Adjust color if needed
                    />
                }
                label="Enable SSO With SAML Broker"
                sx={{ mt: 2 }}
                />
                
              </Box>
              <Box
                display="flex"
                justifyContent="start"
                mt="20px"
                paddingLeft="2rem"
              >
                <Button
                  type="submit"
                  style={{
                    color: "white",
                    backgroundColor: "#ff7a59",
                    borderRadius: "2px",
                    boxShadow: "none",
                  }}
                  variant="contained"
                >
                  Save
                </Button>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </Box>
            </form>
          </Box>
        </Grid>
        {
        formData.certificate ? 
        <Grid item xs={12} md={6}>
          <Box 
           display="grid"
           gap="10px"
           gridTemplateColumns="repeat(4, minmax(0, 1fr))"
           sx={{
             paddingRight : "2rem",
             marginTop: 2,
             paddingLeft: "1rem",
             display: "flex",
             flexDirection: "column",
             alignItems: "start",
             width: "100%",
           }}> 
            <Typography variant="h3" component="h3" fontWeight={700}>
                IDP Metadata
            </Typography>
            <TextField  
                fullWidth         
                variant="outlined"
                type="text"
                label="Identity Provider Identifier or Issuer URL"
                value={idpEntityId}
                sx={{ mt: 2 }}
                InputProps={{
                    readOnly: true,
                    endAdornment: copiedIdpEntityId ? (
                      <Chip
                        label="Copied"
                        style={{
                          color: "white",
                          backgroundColor: "#ff7a59",
                        }}
                      />
                    ) : (
                      <CopyToClipboard
                        text={idpEntityId}
                      >
                        <IconButton
                          aria-label="delete"
                          onClick={onclickCopiedIdpEntityId}
                        >
                          <ContentCopyIcon />
                        </IconButton>
                      </CopyToClipboard>
                    ),
                  }}
            />
            <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Identity Provider single sign-on URL"
                value={idpSSOUrl}
                sx={{ mt: 2 }}
                InputProps={{
                    readOnly: true,
                    endAdornment: copiedIdpSsoUrl ? (
                      <Chip
                        label="Copied"
                        style={{
                          color: "white",
                          backgroundColor: "#ff7a59",
                        }}
                      />
                    ) : (
                      <CopyToClipboard
                        text={idpSSOUrl}
                      >
                        <IconButton
                          aria-label="delete"
                          onClick={onclickCopiedIdpSsoUrl}
                        >
                          <ContentCopyIcon />
                        </IconButton>
                      </CopyToClipboard>
                    ),
                  }}
            />
               <TextField
                fullWidth
                multiline
                variant="outlined"
                type="text"
                label="X.509 Certificate"
                value={formData.certificate}
                sx={{ mt: 2 }}
                InputProps={{
                    readOnly: true,
                    endAdornment: copiedCertificate ? (
                      <Chip
                        label="Copied"
                        style={{
                          color: "white",
                          backgroundColor: "#ff7a59"
                        }}
                        
                      />
                    ) : (
                      <CopyToClipboard
                        text={formData.certificate}
                      >
                        <IconButton
                          aria-label="delete"
                          onClick={onclickCopiedCertificate}
                        >
                          <ContentCopyIcon />
                        </IconButton>
                      </CopyToClipboard>
                    ),
                  }}
            />
          </Box>
        </Grid> : 
        <></>
        }
          
      </Grid>
    </>
  );
};

export default SAMLBroker;
