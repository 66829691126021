import { CssBaseline, ThemeProvider } from "@mui/material";
import axios from "axios";
import qs from "qs";
import React, { useEffect, useState } from "react";
import { Outlet, Route, Routes, useNavigate ,Navigate} from "react-router-dom";
import * as yup from "yup";
import { ColorModeContext, useMode } from "../../theme";
import Sidebar from "../global/Sidebar";
import Topbar from "../global/Topbar";
import Contactsync from "./multipleidpsettings/contactsync";
import Loginaudit from "./loginaudit";
import Multipleidp from "./multipleidpsettings";
import Pagerestriction from "./pageconfiguration";
import BasicTable from "./testsso";
import Contact from "./contact";
import Notfound from "../../components/notfound";
import SAMLBroker from "./samlbroker";
import AccountSAMLBroker from "./accountsamlbroker";

function Configuration() {
  const navigate = useNavigate();
  const appId = localStorage.getItem("app_id");
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const [mapAlert, setmapAlert] = useState(false);
  const [oauthSettingalert, setoauthSettingalert] = useState(false);
  const [errorAlert, seterrorAlert] = useState(false);
  const [progressbar, setProgressbar] = useState(false);
  const [res, setres] = useState("Configuration saved successfully");
  const [isInstruction, setisInstruction] = useState(false);
  const [fetchData, setFetchData] = useState(null);
  const [pagenotrestricted, setpagenotrestricted] = useState(false);
  const [idpData, setidpData] = useState(false);
  const [idpId, setIdpId] = useState("");
  const [testAttributes, setTestAttributes] = useState({});
  const [mapErrorAlert, setMapErrorAlert] = useState(false);
  const [hubContactList,setHubContactList] = useState(null)
  const [websitePageData, setWebsitePageData] = useState(null);
  const [landingPageData, setLandingPageData] = useState(null);
  const [blogPageData, setBlogPageData] = useState(null);
  const [contactList,setContactList] =  useState([])
  const [value, setValue] = useState("1");
  const [isLoading,setIsLoading] = useState(false)
  const [samlProgressbar, setSamlProgressbar] = useState(false);
  const [samlAlert, setSamlAlert] = useState(false);
  const [samlErrorAlert, setSamlErrorAlert] = useState(false);
  const [samlFormData, setSamlFormData] = useState({
    app_id: appId,
    sp_entity_id: '',
    acs_url: '',
    idp_list: [], 
    is_enabled: false,
    certificate : ''
  });
  const [hubspotAccountSamlProgressbar, setHubspotAccountSamlProgressbar] = useState(false);
  const [hubspotAccountSamlAlert, setHubspotAccountSamlAlert] = useState(false);
  const [hubspotAccountSamlErrorAlert, setHubspotAccountSamlErrorAlert] = useState(false);
  const [hubspotAccountSamlFormData, setHubspotAccountSamlFormData] = useState({
    app_id: appId,
    sp_entity_id: '',
    acs_url: '',
    idp_list: [], 
    is_enabled: false,
    certificate : ''
  });
  const checkoutSchema = yup.object().shape({
    appname: yup.number().required("required"),
    idp_id: yup.string().required("required"),
    idp_name: yup.string().required("required"),
    client_id: yup.string().required("required"),
    client_secret: yup.string().required("required"),
    scopes: yup.string().required("required"),
    auth_endpoint: yup.string().required("required"),
    token_endpoint: yup.string().required("required"),
    userinfo_endpoint: yup.string().required("required"),
    email: yup.string(),
    firstname: yup.string(),
    lastname: yup.string(),
    company: yup.string(),
  });
  const updateIDPData = (idpdata) => {
    setidpData(idpdata);
  };
  const updateSamlFormData = (data) =>{
    setSamlFormData(data);
  }
  const updateHubspotAccountSamlFormData = (data) =>{
    setHubspotAccountSamlFormData(data);
  }
  const updateIDPID = (idp_id) => {
    setIdpId(idp_id);
  };
  const handleChangetab = (event, newValue) => {
    setValue(newValue);
  };
  async function deleteIDPConfiguration(idp_id,app_id) {
    let headers = {
      Authorization: `Bearer ${localStorage.getItem("mossotoken")}`,
    }
    var result = await axios
      .delete(process.env["REACT_APP_DELETE_IDP"] + idp_id + '/' + app_id , {headers})
      .then(async (response) => {
        await getConfigurationDetails();
        await fetchSamlData();
        return true
      })
      .catch((error) => {
        console.error("There was an error!", error);
        return false
      });
      return result
  }

  const getcontactproperties = async () => {
    const result = await axios({
      method: "get",
      url: process.env["REACT_APP_GET_CONTACT_PROPERTIES"]+localStorage.getItem("app_id"),
      headers: { Authorization: `Bearer ${localStorage.getItem("mossotoken")}` },
    })
      .then(function (response) {
        return response.data;
      })
      .catch(function (response) {
        return [];
      });

      setHubContactList(result)
    return;
  };

  const getConfigurationDetails = async () => {
    const result = await axios({
      method: "get",
      url:
        process.env["REACT_APP_FETCH_APP_DATA"] +
        localStorage.getItem("app_id"),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("mossotoken")}`,
      },
    })
      .then(function (response) {
        setisInstruction(true);
        localStorage.setItem("isdata", true);
        setpagenotrestricted(true);
        return response.data;
      })
      .catch(function (response) {
        setisInstruction(false);
        return false;
      });
    if (result) {
      setFetchData(result);
    } else {
      setFetchData([]);
    }
  };
  const updateTestAtrribute = (data) => {
    setTestAttributes(data);
  };
  useEffect(() => {
    getcontactproperties();
    getConfigurationDetails();
    fetchSamlData();
    fetchHubspotAccountSamlData();
    getFormDataValues("website_page",false);
    getFormDataValues("landing_page",false);
    getFormDataValues("blog_page",false);
  }, [testAttributes]);
   
  const SidebarLayout = ({ title, subtitle, cust_css }) => (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <Sidebar isSidebar={isSidebar} />
          <main className="content" style={cust_css}>
            <Topbar
              setIsSidebar={setIsSidebar}
              title_top={title}
              subtitle_top={subtitle}
            />
            <Outlet />
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
  const fetchSamlData = async () => {
    try {
      const response = await axios({
        method: "get",
        url: process.env["REACT_APP_FETCH_BROKER_DATA"]+appId,
        headers: { Authorization: `Bearer ${localStorage.getItem("mossotoken")}` },
      })
      const data = response.data; 
      setSamlFormData({
        app_id: appId,
        sp_entity_id: data.sp_entity_id,
        acs_url: data.acs_url,
        idp_list: data.idp_list,
        is_enabled: data.is_enabled,
        certificate: data.certificate
      });
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchHubspotAccountSamlData = async () => {
    try {
      const response = await axios({
        method: "get",
        url: process.env["REACT_APP_FETCH_HUB_ACCOUNT_BROKER_DATA"]+appId,
        headers: { Authorization: `Bearer ${localStorage.getItem("mossotoken")}` },
      })
      const data = response.data; 
      setHubspotAccountSamlFormData({
        app_id: appId,
        sp_entity_id: data.sp_entity_id,
        acs_url: data.acs_url,
        idp_list: data.idp_list,
        is_enabled: data.is_enabled,
        certificate: data.certificate
      });
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const isTokenValidate = async (token) => {
    const result = await axios({
      method: "get",
      url: process.env["REACT_APP_TOKEN_VALIDATE_URL"],
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(function (response) {
        return response.status;
      })
      .catch(function (response) {
        localStorage.clear();
        navigate("/");
        return response.status;
      });
    return result;
  };
  const getFormDataValues = async (page_type,refresh) => {
    setIsLoading(refresh)
    const result = await axios({
      method: "get",
      url:
        process.env["REACT_APP_FETCH_PAGES_DATA"] +
        localStorage.getItem("app_id") +
        "/" +
        page_type,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("mossotoken")}`,
      },
    })
      .then(function (response) {
        setIsLoading(false)
        return response.data;
      })
      .catch(function (response) {
        setIsLoading(false)
        return false;
      });
    if (result) {
      if (Array.isArray(result.page_data)) {
        if (page_type === "website_page") {
          setWebsitePageData(result.page_data);
        } else if(page_type === "landing_page") {
          setLandingPageData(result.page_data);
        } else{
          setBlogPageData(result.page_data)
        }
      
        setContactList(result.contact_list)
      } 
    }
  };
  const handleSamlFormSubmit = async (event, data) => {
    setSamlProgressbar(true);
    event.preventDefault();
    try {
      await axios({
        method: "post",
        url: process.env["REACT_APP_SAVE_BROKER_APP"],
        data: data,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("mossotoken")}`,
          "Content-Type": "application/json"
        },
      })
      .then(function (response) {
        setSamlProgressbar(false);
        setSamlAlert(true);
        setSamlFormData(data)
        if(!data.certificate){
          fetchSamlData();
        }
        setTimeout(() => {
          setSamlAlert(false);
        }, 5000);
      })
      .catch(function (response) {
        setSamlErrorAlert(true);
        setSamlProgressbar(false);
        setTimeout(() => {
            setSamlErrorAlert(false);
        }, 5000);
      });;
      // Optionally, you can navigate to another page or perform any other action upon successful submission
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const handleHubspotAccountSamlFormSubmit = async (event, data) => {
    setHubspotAccountSamlProgressbar(true);
    event.preventDefault();
    try {
      await axios({
        method: "post",
        url: process.env["REACT_APP_SAVE_HUB_ACCOUNT_BROKER_APP"],
        data: data,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("mossotoken")}`,
          "Content-Type": "application/json"
        },
      })
      .then(function (response) {
        setHubspotAccountSamlProgressbar(false);
        setHubspotAccountSamlAlert(true);
        setHubspotAccountSamlFormData(data)
        if(!data.certificate){
          fetchHubspotAccountSamlData();
        }
        setTimeout(() => {
          setHubspotAccountSamlAlert(false);
        }, 5000);
      })
      .catch(function (response) {
        setHubspotAccountSamlErrorAlert(true);
        setHubspotAccountSamlProgressbar(false);
        setTimeout(() => {
            setHubspotAccountSamlErrorAlert(false);
        }, 5000);
      });;
      // Optionally, you can navigate to another page or perform any other action upon successful submission
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const handleFormSubmit = (event) => {
    isTokenValidate(localStorage.getItem("mossotoken"));
    setProgressbar(true);
    event.preventDefault();
    var pre_data = false;
    if (idpData) {
      pre_data = JSON.parse(idpData);
    }
    
    
    const data = new FormData(event.currentTarget);
    let idp_id = idpId;
    let idp_name = data.get("idp_name")
      ? data.get("idp_name").trim()
      : pre_data.idp_name;
    let login_btn_text = data.get("login_btn_text") ? data.get("login_btn_text").trim() :  pre_data.login_btn_text;
    let appname = data.get('appname') ? data.get("appname").trim()  : pre_data.appname;
    let client_id = data.get("client_id")
      ? data.get("client_id").trim()
      : pre_data.client_id;
    let client_secret = data.get("client_secret")
      ? data.get("client_secret").trim()
      : pre_data.client_secret;
    let send_body = "false";
    let send_header = "true";
    let scopes = data.get("scopes")
      ? data.get("scopes").trim()
      : pre_data.scopes;
    let auth_endpoint = data.get("auth_endpoint")
      ? data.get("auth_endpoint").trim()
      : pre_data.auth_endpoint;
    let token_endpoint = data.get("token_endpoint")
      ? data.get("token_endpoint").trim()
      : pre_data.token_endpoint;
    let userinfo_endpoint = data.get("userinfo_endpoint")
      ? data.get("userinfo_endpoint").trim()
      : pre_data.userinfo_endpoint;
    let flow_type = data.get('flow_type') ? data.get('flow_type').trim() : pre_data.flow_type;
    let sync_enabled = "false";
    let loginaudit_enabled = "false";
    let email = "false";
    let firstname = "false";
    let lastname = "false";
    let company = "false";
    let custom_attr = "false";
    if (data.get("mapping")) {
      send_body = pre_data.send_body;
      send_header = pre_data.send_header;
      sync_enabled = data.has("sync_enabled").toString();
      loginaudit_enabled = data.has("loginaudit_enabled").toString();
      email = data.get("email") ? data.get("email") : "false";
      firstname = data.get("firstname") ? data.get("firstname") : "false";
      lastname = data.get("lastname") ? data.get("lastname") : "false";
      company = data.get("company") ? data.get("company") : "false";
      custom_attr = data.get("custom_attr") ? (JSON.parse(data.get("custom_attr"))['custom_attr'].length==0 ? "false" : JSON.parse(data.get("custom_attr"))['custom_attr'] ): "false"
    } else {
      send_body = data.has("sendinbody").toString();
      send_header = data.has("sendinheader").toString();
      sync_enabled = pre_data ? pre_data.sync_enabled : "false";
      loginaudit_enabled = pre_data ? pre_data.loginaudit_enabled : "false";
      email = pre_data ? pre_data.sync_attr.email : "false";
      firstname = pre_data ? pre_data.sync_attr.firstname : "false";
      lastname = pre_data ? pre_data.sync_attr.lastname : "false";
      company = pre_data ? pre_data.sync_attr.company : "false";
      custom_attr = pre_data ? (pre_data.custom_attr ? pre_data.custom_attr : "false" ): "false"
    }
    if(custom_attr!='false'){
        if(custom_attr[custom_attr.length-1][0]['label']==null || custom_attr[custom_attr.length-1][1]==""){
          custom_attr.splice(custom_attr.length-1,1)
        }
        if(custom_attr.length==0){
          custom_attr = 'false'
        }
      }
    
    let map = data.get("mapping") ? true : false;
    let sync_attr = {
      email: email,
      firstname: firstname,
      lastname: lastname,
      company: company,
    };

    let pre_post_data = {
      app_id: localStorage.getItem("app_id"),
      idp_id: idp_id,
      idp_name: idp_name,
      login_btn_text:login_btn_text,
      appname : appname,
      client_id: client_id,
      client_secret: client_secret,
      scopes: scopes,
      send_header: send_header,
      send_body: send_body,
      auth_endpoint: auth_endpoint,
      token_endpoint: token_endpoint,
      userinfo_endpoint: userinfo_endpoint,
      flow_type:flow_type,
      sync_enabled: sync_enabled,
      loginaudit_enabled: loginaudit_enabled,
      sync_attr: sync_attr,
      custom_attr : custom_attr
    };
    let post_data = qs.stringify(pre_post_data);
    let testConfig_url =
      process.env["REACT_APP_TEST_CONFIGURATION_URL"] +
      "?app_id=" +
      localStorage.getItem("app_id") +
      "&idp_id=" +
      idp_id +
      "&sso_redirect=" +
      process.env["REACT_APP_REDIRECT_URL_AFTER_AUTHENTICATION"] +
      "&testsso=true";

    if (
      map &&
      email == "false" &&
      firstname == "false" &&
      lastname == "false" &&
      company == "false" &&
      custom_attr == "false"
    ) {
      setProgressbar(false);
      setres(
        "No attribute maped ! Please map at least one IDP attribute below.!"
      );
      setMapErrorAlert(true);
      setTimeout(() => {
        setMapErrorAlert(false);
      }, 5000);
    } else {
      axios({
        method: "post",
        url: process.env["REACT_APP_SAVE_OAUTH_URL"],
        data: post_data,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("mossotoken")}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then(function (response) {
          setProgressbar(false);
          if (map) {
            setmapAlert(true);
            setTimeout(() => {
              setmapAlert(false);
            }, 5000);
          } else {
            setoauthSettingalert(true);
            setTimeout(() => {
              setoauthSettingalert(false);
            }, 10000);
            window.open(
              testConfig_url,
              "_blank",
              "location=1,status=1,scrollbars=1,  width=600,height=700"
            );
          }
          setisInstruction(true);
          setpagenotrestricted(true);
          getConfigurationDetails();
          localStorage.setItem("isdata", true);
        })
        .catch(function (response) {
          setProgressbar(false);
          setres("Something went wrong!");
          seterrorAlert(true);
          setTimeout(() => {
            setres("Configuration saved successfully");
            seterrorAlert(false);
          }, 5000);
          if (response.response.status == 401) {
            localStorage.clear();
            navigate("/");
          }
        });
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  let props = {
    getConfigurationDetails: getConfigurationDetails,
    handleFormSubmit: handleFormSubmit,
    checkoutSchema: checkoutSchema,
    isInstruction: isInstruction,
    progressbar: progressbar,
    mapalert: mapAlert,
    oauthsettingalert: oauthSettingalert,
    erroralert: errorAlert,
    pagenotrestricted: pagenotrestricted,
    fetchdata: fetchData,
    testAttributes: testAttributes,
    mapErrorAlert: mapErrorAlert,
    hubContactList: hubContactList,
    SidebarLayout: SidebarLayout,
    idpData: idpData,
    updateTestAtrribute: updateTestAtrribute,
    updateIDPData: updateIDPData,
    deleteIDPConfiguration: deleteIDPConfiguration,
    updateIDPID: updateIDPID,
    isTokenValidate : isTokenValidate,
    getFormDataValues : getFormDataValues,
    isLoading: isLoading,
    websitePageData : websitePageData,
    blogPageData : blogPageData,
    landingPageData :landingPageData,
    contactList : contactList,
    value : value,
    handleChangetab : handleChangetab,
    samlFormData : samlFormData,
    updateSamlFormData : updateSamlFormData,
    samlAlert : samlAlert,
    samlErrorAlert : samlErrorAlert,
    samlProgressbar : samlProgressbar,
    handleSamlFormSubmit : handleSamlFormSubmit,
    hubspotAccountSamlFormData : hubspotAccountSamlFormData,
    updateHubspotAccountSamlFormData : updateHubspotAccountSamlFormData,
    hubspotAccountSamlAlert : hubspotAccountSamlAlert,
    hubspotAccountSamlErrorAlert : hubspotAccountSamlErrorAlert,
    hubspotAccountSamlProgressbar  :hubspotAccountSamlProgressbar,
    handleHubspotAccountSamlFormSubmit : handleHubspotAccountSamlFormSubmit

  };
  const Home = (props) => {
    return <Navigate to="/sso/app-configurations" />
  }

  return (
    <>
      <Routes>
        <Route element={<Home {...props} />} path="" />
        <Route
          index
          element={<Multipleidp {...props} />}
          path="app-configurations/*"
        />

        <Route
          element={
            <SidebarLayout
              title="Contact Sync Mapping"
              subtitle="Add Your Attribute"
            />
          }
        >
          <Route
            element={<Contactsync {...props} />}
            path="contactsync-configurations"
          />
        </Route>

        <Route element={<SidebarLayout title="HubSpot Enterprise SSO Configuration" subtitle="" />}>
          <Route
            element={<SAMLBroker {...props} />}
            path="saml-broker"
            exact
          />
        </Route>

        <Route element={<SidebarLayout title="HubSpot Account SSO Configuration" subtitle="" />}>
          <Route
            element={<AccountSAMLBroker {...props} />}
            path="account-sso-configuration"
            exact
          />
        </Route>

        <Route element={<SidebarLayout title="Page Restriction" subtitle="" />}>
          <Route
            element={<Pagerestriction {...props} />}
            path="pagerestriction"
            exact
          />
        </Route>
        <Route element={<SidebarLayout title="Contact Us" subtitle="" />}>
          <Route
            element={<Contact {...props} />}
            path="contact"
            exact
          />
        </Route>
        <Route element={<SidebarLayout title="Login Audits" subtitle="" />}>
          <Route element={<Loginaudit {...props} />} path="loginaudit" exact />
        </Route>
        <Route element={<BasicTable {...props} />} path="testSSO" exact />
        <Route element={<Notfound {...props} />} path="404-page-error" exact />
      </Routes>
    </>
  );
}

export default Configuration;
