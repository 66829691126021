import React from 'react'
import { useNavigate, useParams, Link ,useSearchParams } from "react-router-dom";
import Container from '@mui/material/Container';
import { Box, Button, TextField, Typography ,Stack} from "@mui/material";


function Notfound(props) {
  return (
    <Container maxWidth="sm">
      <div style={{
    display: "flex",
    justifyContent:"center",
    alignItems:"center",
    height: "100vh"}}>
        <Stack>
        <Typography variant='h4'>
            404 | Page Not Found
        </Typography>
        <p style={{textAlign:"center",fontSize:"1.5rem"}}>
        <Link to="/sso/app-configurations">Go to Home </Link>

        </p>
   
        </Stack>
        

        
        
</div>
    </Container>
   
  )
}

export default Notfound