import LogoutIcon from "@mui/icons-material/Logout";
import { Box, IconButton, useTheme } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import { ColorModeContext, tokens } from "../../theme";

const Topbar = ({ title_top, subtitle_top }) => {
  const [noOfDaysLeft, setNoOfDaysLeft] = useState(null);
  const [plandays, setPlandays] = useState(14);
  const [licenseType, setlicenseType] = useState("Trial");
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const navigate = useNavigate();
  const handleClick = (e) => {
    e.preventDefault();
    localStorage.clear();
    navigate("/");
  };

  const getdatediff = (date1, date2) => {
    let days = Math.round((date1 - date2) / (1000 * 60 * 60 * 24));
    if (days <= 0) {
      return 0;
    }
    return days;
  };

  const getplanexpiredate = async () => {
    const result = await axios({
      method: "get",
      url:
        process.env["REACT_APP_PLAN_EXPIRE_DATE"] +
        localStorage.getItem("app_id"),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("mossotoken")}`,
      },
    })
      .then(function (response) {
        return response.data;
      })
      .catch(function (response) {
        localStorage.clear();
        navigate("/");
        return false;
      });

    if (result) {
      let created_at = new Date(result.created_at);
      let license_exp = new Date(result.license_exp);
      setPlandays(getdatediff(license_exp, created_at));
      setNoOfDaysLeft(getdatediff(license_exp, new Date()));
      setlicenseType(result.license_type);
    }
  };

  useEffect(() => {
    getplanexpiredate();
  }, []);

  return (
    (
    <Stack spacing={0} paddingBottom={0}>
      <AppBar
        position="static"
        style={{
          color: "white",
          background: "linear-gradient(to right bottom, #00BDA5, #00A4BD)",
          
        }}
        elevation="0"
        
      >
        <Toolbar>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1 }}
            fontFamily="revert"
            fontSize="1.5rem"
          >
            You have {noOfDaysLeft} days remaining of your {plandays} days{" "}
            {licenseType} plan
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        display="flex"
        justifyContent="space-between"
        p={2}
        paddingLeft="3rem"
        paddingBottom={0}
      >
        {/* SEARCH BAR */}
        {/* <Box
        display="flex"
        backgroundColor={colors.primary[400]}
        borderRadius="3px"
      >
        <Header title="Hubspot" subtitle="Configure your IDP" />
      </Box>  */}
        <Dialog
          fullWidth={true}
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title"></DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Typography variant="h5" component="h2" color={"rgb(38 55 70)"}>
                {" "}
                Are you sure you want to logout?
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              style={{ color: "white", backgroundColor: "#ff7a59" }}
            >
              No
            </Button>
            <Button
              onClick={handleClick}
              style={{ color: "white", backgroundColor: "#ff7a59" }}
              autoFocus
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>

        <Header title={title_top} subtitle={subtitle_top} />
        {/* ICONS */}
        <Box display="flex">
          {/* <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton> */}

          <IconButton onClick={handleClickOpen}>
            Logout&nbsp;
            <LogoutIcon />
          </IconButton>
        </Box>
      </Box>
    </Stack>)
  );
};

export default Topbar;
