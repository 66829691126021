import { Box, Button, TextField } from "@mui/material";
import Alert from "@mui/material/Alert";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { Formik } from "formik";
import { sha512 } from "js-sha512";
import _ from "lodash";
import React, { useState } from "react";
import * as yup from "yup";

const Contact = () => {
  const phoneRegExp =
    /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

  const checkoutSchema = yup.object().shape({
    email: yup.string().email("invalid email").required("required"),
    phone: yup
      .string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("required"),
    query: yup.string().required("required"),
  });

  const initialValues = {
    email: localStorage.getItem("username") ? localStorage.getItem("username") : "",
    phone: "",
    query: "",
  };
  const [alert, setAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [progressbar, setProgressbar] = useState(false);
  const [res, setres] = useState("Query submited successfully !!");
  const handleFormSubmit = (event) => {
    setProgressbar(true);
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let email = data.get("email").trim();
    let phone = data.get("phone").trim();
    let query = data.get("query").trim();
    let post_data = {
      email: email,
      phone: phone,
      query: "<b>[HubSpot Single Sign On App]</b> <br>" + query + " <br><br>",
      ccEmail: "hubspotsupport@xecurify.com",
    };
    const getAuthorizationToken = (time) => {
      const timeStamp = String(time);
      const customer_id = "16555";
      const api_key = "fFd2XcvTGDemZvbw1bcUesNJWEqKbbUq";
      const stringTohash = customer_id + timeStamp + api_key;
      var hash = sha512(stringTohash);
      return hash;
    };
    const curr_time = _.now();
    const auth_token = getAuthorizationToken(curr_time);
    axios({
      method: "post",
      url: process.env["REACT_APP_CONTACT_US"],
      data: post_data,
      headers: {
        "Customer-Key": "fFd2XcvTGDemZvbw1bcUesNJWEqKbbUq",
        Timestamp: String(curr_time),
        Authorization: auth_token,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        setProgressbar(false);
        setAlert(true);
        setTimeout(() => {
          setAlert(false);
        }, 5000);
      })
      .catch(function (response) {
        setErrorAlert(true);
        setProgressbar(false);
        setTimeout(() => {
          setErrorAlert(false);
        }, 5000);
      });
  };

  const AlertLayout = () => (
    <>
      {alert ? (
        <Alert severity="success">Query submited successfully !!</Alert>
      ) : (
        <></>
      )}
      {errorAlert ? (
        <Alert severity="error">Something went wrong!!</Alert>
      ) : (
        <></>
      )}
    </>
  );

  const Progress = () =>
    progressbar ? <LinearProgress color="secondary" /> : <></>;

  return (
    <Box m="40px">
      <AlertLayout />
      <Alert severity="info">
        <Typography variant="h6" component="h2">
          Need any help? Just send us a query so we can help you.
        </Typography>
      </Alert>
      <Progress />
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleFormSubmit}>
            <Box
              display="grid"
              gap="10px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                marginTop: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                width: "80%",
              }}
            >
              <TextField
                fullWidth
                variant="outlined"
                type="email"
                label="Email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                name="email"
                error={!!touched.email && !!errors.email}
                helperText={touched.email && errors.email}
                sx={{ mt: 2 }}
              />
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Phone"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.phone}
                name="phone"
                error={!!touched.phone && !!errors.phone}
                helperText={touched.phone && errors.phone}
                sx={{ mt: 2 }}
              />
              <TextField
                fullWidth
                id="outlined-multiline"
                label="Query"
                multiline
                rows={6}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.query}
                name="query"
                error={!!touched.query && !!errors.query}
                helperText={touched.query && errors.query}
                sx={{ mt: 2 }}
              />
            </Box>
            <Box display="flex" justifyContent="start" mt="20px">
              <Button
                type="submit"
                style={{
                  color: "white",
                  backgroundColor: "#ff7a59",
                  borderRadius: "2px",
                  boxShadow: "none",
                }}
                variant="contained"
              >
                Submit
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default Contact;
